import React from "react";
import { Link } from "react-router-dom";

function NotFound() {

    return (
        <div className="main-container fullscreen">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-xl-5 col-lg-6 col-md-7">
                        <div className="text-center">
                            <h1 className="display-1 text-primary">4&#x1f635;4</h1>
                            <p>
                                The page you were looking for was not found.
                            </p>
                            <p>
                                <Link to="/dashboard">Click here to go home</Link>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default NotFound;