import { React, useEffect, useState } from "react";
import { Tooltip, OverlayTrigger } from "react-bootstrap";

function Avatars(props) {

    const [member_list, setMember_list] = useState([]);

    useEffect(() => {
        let temp_member_list = [];
        if (props.master_members_list.length > 0 && props.users) {
            //console.log(props.users);
            //console.log(props.master_members_list);
            props.users.forEach(element => {
                //console.log(element);
                
                const user = props.master_members_list.find(e => e.uid === element);
                //console.log(user);
                temp_member_list.push({
                    uid: user.uid,
                    first_name: user.first_name,
                    last_name: user.last_name,
                    avatar_url: user.avatar_url
                });
            });
            setMember_list(temp_member_list);
        }
    }, [props]);

    return (
        <ul className={props.align === "center" ? "avatars text-center" : "avatars"}>
            {member_list &&
                member_list.map((member) => (
                    <li key={member.uid}>
                        <OverlayTrigger transition={false} placement="top" overlay={
                            <Tooltip>{member.first_name + ' ' + member.last_name}</Tooltip>
                        }>
                            <img alt={member.first_name + ' ' + member.last_name} src={member.avatar_url} className="avatar" data-toggle="tooltip" data-title={member.first_name + ' ' + member.last_name} data-original-title="" title="" />
                        </OverlayTrigger>
                    </li>
                ))
            }
        </ul>
    )

}

export default Avatars;