import { React } from 'react';
import Avatars from "./Avatars";

function ActivityCard(props) {

    return (
        <li className="list-group-item">
            <div className="media align-items-center">
                <ul className="avatars">
                    <li>
                        <div className={props.activity.type === 'Delete' ? "avatar bg-danger" : "avatar bg-primary"}>
                            <i className="material-icons">{props.activity.type === 'Delete' ? "remove" : props.activity.type.toLowerCase()}</i>
                        </div>
                    </li>
                    <Avatars users={[props.activity.user]} master_members_list={props.master_members_list}></Avatars>
                </ul>
                <div className="media-body">
                    <div>
                        <span data-filter-by="text" className="SPAN-filter-by-text"><strong>{props.activity.type}</strong></span> {props.activity.descr}
                    </div>
                    <span className="text-small SPAN-filter-by-text" data-filter-by="text">{props.activity.date.toDate().toLocaleDateString('en-us')} {props.activity.date.toDate().toLocaleDateString('en-us')}</span>
                </div>
            </div>
        </li>
    )
}

export default ActivityCard;