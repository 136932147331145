import { React, useState } from "react";
//import { Card } from "react-bootstrap";
import { Link } from "react-router-dom";
import Avatars from "./Avatars";

function Teamcard(props) {
    //const navigate = useNavigate();
    const [toggleManage, setToggleManage] = useState(false);

    const handleToggleManage = (e) => {
        e.preventDefault();
        setToggleManage(!toggleManage);
        //console.log(props.data);
    }

    return (
        <div className="card card-team">
            <div className="card-body">
                <div className={toggleManage ? "dropdown card-options show" : "dropdown card-options"}>
                    <button onClick={e => handleToggleManage(e)} className="btn-options" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded={toggleManage}>
                        <i className="material-icons">more_vert</i>
                    </button>
                    <div className={toggleManage ? "dropdown-menu dropdown-menu-right show" : "dropdown-menu dropdown-menu-right"}>
                        <a className="dropdown-item" href="/">Manage</a>
                    </div>
                </div>
                <div className="card-title">
                    <Link to={"/team/" + props.data.id}><h5 data-filter-by="text" className="H5-filter-by-text">{props.data.name}</h5></Link>
                    <span>{props.data.descr}</span>
                </div>
                <Avatars users={props.data.members} master_members_list={props.master_members_list} />
            </div>
        </div>
    );
}
export default Teamcard;