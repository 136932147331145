import "./Socials.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faFacebook, faInstagram} from '@fortawesome/free-brands-svg-icons';

function Socials() {

    return (
        <ul className="social-icons">
            <li><a href="https://www.facebook.com/ShawgoRealEstatellc" target="_blank" rel='noreferrer'><FontAwesomeIcon icon={faFacebook} size="2x" /></a></li>
            <li><a href="https://www.instagram.com/shawgo_real_estate" target="_blank" rel='noreferrer'><FontAwesomeIcon icon={faInstagram} size="2x" /></a></li>
        </ul>
    )
}

export default Socials;
