import React, { useState, useEffect } from "react";
//import { Card } from "react-bootstrap";
//import { useHistory, Link } from "react-router-dom";
import { updateProfile, uploadAvatar, changePassword, getTeams } from "./firebase";
import { FormErrors } from "./FormErrors";

function Account(props) {

  const default_avatar_url = "https://firebasestorage.googleapis.com/v0/b/customer-portal-shawgo.appspot.com/o/avatars%2Fdefault_user.jpg?alt=media&token=6ed7a745-44a1-4aa5-bafe-638339cc9da2";
  const [first_name, setFirstName] = useState("");
  const [last_name, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [new_password, setNewPasword] = useState("");
  const [confirm_new_password, setConfirm_new_password] = useState("");
  const [avatar_url, setAvatar] = useState("");
  const [title, setTitle] = useState("");
  const [current_team, setCurrentTeam] = useState("");
  const [myteams, setMyTeams] = useState([]);
  const [currentView, setCurrentView] = useState("profile");
  const [unsaved_changes, setUnsavedChanges] = useState(false);
  const [form_valid, setFormValid] = useState(true);
  const [formErrors, setFormErrors] = useState({ email: '', fname: '', lname: '', phone: '', password: '' });

  useEffect(() => {
    if (props.profile) {
      setFirstName(props.profile.first_name);
      setLastName(props.profile.last_name);
      setEmail(props.profile.email);
      setPhone(props.profile.phone);
      setAvatar(props.profile.avatar_url);
      setTitle(props.profile.title);

      getTeams().then((r) => {
        setMyTeams(r);
      });
    }
  }, [props.profile]);

  const handleViewChange = (e, v) => {
    e.preventDefault();
    setCurrentView(v);
  }

  const handlePasswordChange = (e) => {
    e.preventDefault();
    //let error_msg = '';
    if (validateForm()) {
      changePassword(new_password).then((r) => {
        //console.log(r);
        if (r === "success") {
          //console.log(r);
          setNewPasword("");
          setConfirm_new_password("");
          setCurrentView("profile");
          //send confirmation email that their password was just changed as a safety measure. Possibly sms text as well... 
        }
        else {
          //console.log(r);
          setFormErrors({ email: '', fname: '', lname: '', phone: '', password: r });
        }
      });
    }
    else
      console.log('form not valid...');
  }

  const handleUserInput = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    setUnsavedChanges(true);

    if (name === "profile-first-name") {
      setFirstName(value);
    }
    if (name === "profile-last-name") {
      setLastName(value);
    }
    if (name === "profile-email") {
      setEmail(value);
    }
    if (name === "profile-phone") {
      setPhone(value);
    }

    if (name === "password-new") {
      setNewPasword(value);
    }

    if (name === "password-new-confirm") {
      setConfirm_new_password(value);
    }

    if (name === "profile-title") {
      setTitle(value);
    }

    if (name === "profile-current-team") {
      setCurrentTeam(value);
    }

    //validateForm();
  }

  const validateForm = () => {
    const valid_email_re = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
    const phone_re = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
    let form_valid = true;
    let errors = { fname: '', lname: '', email: '', phone: '', password: '' };

    console.log(first_name.length)
    if (first_name.length > 1) {

    }
    else {
      errors.fname = 'First Name is a Required Field!';
      form_valid = false;
    }

    if (last_name.length > 1) {

    }
    else {
      errors.lname = 'Last Name is a Required Field!';
      form_valid = false;
    }

    if (valid_email_re.test(email)) {

    }
    else {
      errors.email = 'Email Address in the wrong format. Plese use xxx@xxx.xxx format';
      form_valid = false;
    }

    if (phone_re.test(phone)) {

    }
    else {
      errors.phone = 'Phone number in the wrong format: XXX-XXX-XXXX';
      form_valid = false;
    }

    if (new_password === confirm_new_password) {

    }
    else {
      errors.password = 'New password does not match the confirmation password!';
      console.log(new_password + ' | ' + confirm_new_password);
      form_valid = false;
    }

    var hasUpperCase = /[A-Z]/.test(new_password);
    var hasLowerCase = /[a-z]/.test(new_password);
    var hasNumbers = /\d/.test(new_password);

    if (currentView === 'password') {
      if (new_password.length > 0) {
        if (new_password.length >= 8 && hasUpperCase && hasLowerCase && hasNumbers) {

        }
      }
      else {
        errors.password = 'Password complexity not met. Must be 8 characters with a number and an upper case character.';
        form_valid = false;
      }
    }


    setFormErrors(errors);
    setFormValid(form_valid);

    return form_valid;
  }

  const handleAvatarChange = async (e) => {
    //console.log('avatar change detected...');
    let file = e.target.files[0];
    //console.log(file);
    let ret = await uploadAvatar(file);
    //console.log(ret);
    setAvatar(ret);
    setUnsavedChanges(true);
  }

  const handleSave = (e) => {
    e.preventDefault();
    validateForm();
    if (form_valid) {
      //console.log('save clicked... yay...');
      updateProfile(first_name, last_name, email, phone, avatar_url, title, current_team).then(r => {
        console.log(r);
        const profile = {
          "avatar_url": avatar_url,
          "email": email,
          "last_name": last_name,
          "uid": "",
          "phone": phone,
          "title": title,
          "first_name": first_name,
          "current_team": current_team
        };
        props.onProfileChange(profile);
        setUnsavedChanges(false);
      });
    }
  }

  return (
    <div className="main-container">
      <div className="container">
        <div className="row justify-content-center mt-5">
          <div className="col-lg-3 mb-3">
            <ul className="nav nav-tabs flex-lg-column" role="tablist">
              <li className="nav-item">
                <a className={currentView === 'profile' ? 'nav-link active' : 'nav-link'} onClick={e => handleViewChange(e, 'profile')} id="profile-tab" data-toggle="tab" href="#profile" role="tab" aria-controls="profile" aria-selected="true">Your Profile</a>
              </li>
              <li className="nav-item">
                <a className={currentView === 'password' ? 'nav-link active' : 'nav-link'} onClick={e => handleViewChange(e, 'password')} id="password-tab" data-toggle="tab" href="#password" role="tab" aria-controls="password" aria-selected="true">Password</a>
              </li>
              <li className="nav-item">
                <a className={currentView === 'notifications' ? 'nav-link active' : 'nav-link'} onClick={e => handleViewChange(e, 'notifications')} id="notifications-tab" data-toggle="tab" href="#notifications" role="tab" aria-controls="notifications" aria-selected="true">Notification Settings</a>
              </li>
            </ul>
          </div>
          <div className="col-xl-8 col-lg-9">
            <div className="card">
              <div className="card-body">
                <div className="tab-content">
                  {currentView === 'profile' &&
                    <div className="tab-pane fade show active" role="tabpanel" id="profile">
                      <div className="media mb-4">
                        {avatar_url === "" &&
                          <img alt="" src={default_avatar_url} className="avatar avatar-lg" />
                        }
                        {avatar_url !== "" &&
                          <img alt="" src={avatar_url} className="avatar avatar-lg" />
                        }
                        <div className="media-body ml-3">
                          <div className="custom-file custom-file-naked d-block mb-1">
                            <input type="file" className="custom-file-input d-none" id="avatar-file" onChange={handleAvatarChange} />
                            <label className="custom-file-label position-relative" htmlFor="avatar-file">
                              <span className="btn btn-primary">
                                Upload Profile Picture
                              </span>
                            </label>
                          </div>
                          <small>For best results, use an image at least 256px by 256px in either .jpg or .png format</small>
                        </div>
                      </div>

                      <div className="panel panel-default">
                        <FormErrors formErrors={formErrors} />
                      </div>
                      <form>
                        <div className="form-group row align-items-center">
                          <label className="col-3">First Name</label>
                          <div className="col">
                            <input type="text" placeholder="First name" value={first_name} name="profile-first-name" className="form-control" required="" onChange={e => handleUserInput(e)} />
                          </div>
                        </div>
                        <div className="form-group row align-items-center">
                          <label className="col-3">Last Name</label>
                          <div className="col">
                            <input type="text" placeholder="First name" value={last_name} name="profile-last-name" className="form-control" required="" onChange={e => handleUserInput(e)} />
                          </div>
                        </div>
                        <div className="form-group row align-items-center">
                          <label className="col-3">Job Title</label>
                          <div className="col">
                            <input type="text" placeholder="Job Title" value={title} name="profile-title" className="form-control" onChange={e => handleUserInput(e)} />
                          </div>
                        </div>
                        <div className="form-group row align-items-center">
                          <label className="col-3">Email</label>
                          <div className="col">
                            <input type="email" placeholder="Enter your email address" value={email} name="profile-email" className="form-control" onChange={e => handleUserInput(e)} />
                          </div>
                        </div>
                        <div className="form-group row align-items-center">
                          <label className="col-3">Cell Phone</label>
                          <div className="col">
                            <input type="tel" placeholder="Enter your cell phone number" value={phone} name="profile-phone" className="form-control" onChange={e => handleUserInput(e)} />
                          </div>
                        </div>
                        <hr />
                        <div className="form-group row align-items-center">
                          <label className="col-3">Current Team</label>
                          <div className="col">
                            <select value={current_team} onChange={e => handleUserInput(e)} name="profile-current-team" className="form-select col" aria-label="Current Team">
                              <option defaultValue="">Select a team</option>
                              {myteams.map((team) => (
                                <option key={team.id} value={team.id}> {team.name}</option>
                              ))
                              }
                            </select>
                          </div>
                        </div>
                        <div class="alert alert-warning text-small" role="alert"><span>Your current team setting controls the access you have to projects and tasks. If you have mutiple teams you can use this setting to toggle between team project(s).</span></div>
                        <div className="d-flex justify-content-end">
                          {unsaved_changes && form_valid &&
                            <button type="submit" className="btn btn-primary" onClick={e => handleSave(e)}>Save</button>
                          }
                        </div>
                      </form>
                    </div>
                  }
                  {currentView === 'password' &&
                    <div className="tab-pane fade show active" role="tabpanel" id="password">
                      <FormErrors formErrors={formErrors} />
                      <form>
                        <div className="form-group row align-items-center">
                          <label className="col-3">New Password</label>
                          <div className="col">
                            <input type="password" placeholder="Enter a new password" value={new_password} name="password-new" className="form-control" onChange={e => handleUserInput(e)} />
                            <small>Password must be at least 8 characters long</small>
                          </div>
                        </div>
                        <div className="form-group row align-items-center">
                          <label className="col-3">Confirm Password</label>
                          <div className="col">
                            <input type="password" placeholder="Confirm your new password" value={confirm_new_password} name="password-new-confirm" className="form-control" onChange={e => handleUserInput(e)} />
                          </div>
                        </div>
                        <div className="d-flex justify-content-end">
                          <button type="submit" onClick={e => handlePasswordChange(e)} className="btn btn-primary">Change Password</button>
                        </div>
                      </form>
                    </div>
                  }
                  {currentView === 'notifications' &&
                    <div class="tab-pane fade active show" role="tabpanel" id="notifications">
                      <form>
                        <h6>Activity Notifications</h6>
                        <div class="form-group">
                          <div class="custom-control custom-checkbox custom-checkbox-switch">
                            <input type="checkbox" class="custom-control-input" id="notify-1" checked="" />
                            <label class="custom-control-label" for="notify-1">Someone assigns me to a task</label>
                          </div>
                        </div>
                        <div class="form-group">
                          <div class="custom-control custom-checkbox custom-checkbox-switch">
                            <input type="checkbox" class="custom-control-input" id="notify-2" checked="" />
                            <label class="custom-control-label" for="notify-2">Someone mentions me in a conversation</label>
                          </div>
                        </div>
                        <div class="form-group">
                          <div class="custom-control custom-checkbox custom-checkbox-switch">
                            <input type="checkbox" class="custom-control-input" id="notify-3" checked="" />
                            <label class="custom-control-label" for="notify-3">Someone adds me to a project</label>
                          </div>
                        </div>
                        <div class="form-group mb-md-4">
                          <div class="custom-control custom-checkbox custom-checkbox-switch">
                            <input type="checkbox" class="custom-control-input" id="notify-4" />
                            <label class="custom-control-label" for="notify-4">Activity on a project I am a member of</label>
                          </div>
                        </div>

                      </form>
                    </div>
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div >
  )
}
export default Account;