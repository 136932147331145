//import 'bootstrap/dist/css/bootstrap.min.css';
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import './App.css';
import Login from './Login';
import Privacy from "./Privacy";
import Terms from "./Terms";
import Dashboard from './Dashboard';
import Navbar from "./Navbar";
import Account from "./Account";
//import reactDom from "react-dom";
import { React, useState, useEffect } from "react";
import PrivateRoute from "./PrivateRoute";
import { auth, getTemplates, getUserProfile, getUsers } from "./firebase";
import { useAuthState } from "react-firebase-hooks/auth";
import NotFound from "./NotFound";
import Team from "./Team";
import Project from "./Project";
import Task from "./Task";
import Admin from "./Admin";

function App() {
  const [user] = useAuthState(auth);
  const [master_members_list, setMasterMembersList] = useState([]);
  const [profile, setProfile] = useState();
  const [default_avatar_url] = useState("https://firebasestorage.googleapis.com/v0/b/customer-portal-shawgo.appspot.com/o/avatars%2Fdefault_user.jpg?alt=media&token=6ed7a745-44a1-4aa5-bafe-638339cc9da2");
  const [templates, setTemplates] = useState({});

  useEffect(() => {
    if (user) {
      getUsers().then(r => {
        setMasterMembersList(r)
        console.log(r);
      });
      getUserProfile(user.uid).then((u) => {
        console.log(!u);
        console.log(!profile);
        if (!u && !profile) {
          setProfile({
            "avatar_url": default_avatar_url,
            "email": user.email,
            "last_name": "",
            "uid": user.uid,
            "phone": "",
            "title": "",
            "first_name": ""
          });
        }
        else if (u && !profile)
          setProfile(u);
      });
    }
  }, [default_avatar_url, profile, user]);

  useEffect(() => {
    getTemplates().then(ret => {
      setTemplates(ret);
    })
  }, []);

  const onLogin = (id) => {
    setProfile(getUserProfile(id));
    console.log(id);
  }

  const onProfileChange = (p) => {
    setProfile(p);
  }

  const onLogout = () => {
    setProfile();
  }

  return (
    <div className="App">
      <div className="layout layout-nav-top"></div>
      <Router>
        {profile &&
          <Navbar profile={profile} default_avatar={default_avatar_url} onLogout={onLogout} />
        }
        <Routes>
          <Route exact path="/" element={<Login onLogin={onLogin} />} />
          <Route exact path="/privacy" element={<Privacy />} />
          <Route exact path="/terms" element={<Terms />} />
          <Route path="/dashboard" element={
            <PrivateRoute>
              <Dashboard profile={profile} master_members_list={master_members_list} />
            </PrivateRoute>} />
          <Route path="/account" element={
            <PrivateRoute>
              <Account profile={profile} onProfileChange={onProfileChange} />
            </PrivateRoute>
          } />
          <Route path="/team/:id" element={
            <PrivateRoute>
              <Team profile={profile} master_members_list={master_members_list} />
            </PrivateRoute>
          } />
          <Route path="/project/:id" element={
            <PrivateRoute>
              <Project master_members_list={master_members_list} templates={templates} />
            </PrivateRoute>
          } />
          <Route path="/project/:id/tasks/:task" element={
            <PrivateRoute>
              <Task master_members_list={master_members_list} templates={templates} />
            </PrivateRoute>
          } />
          <Route path="/admin" element={
            <PrivateRoute>
              <Admin master_members_list={master_members_list} />
            </PrivateRoute>
          } />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
