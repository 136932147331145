import { Navigate } from 'react-router-dom'
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "./firebase";
import React from "react";

function PrivateRoute({ children }) {

        const [user, loading, error] = useAuthState(auth);
      
        if (loading) {
          return (
            <div>
             
            </div>
          );
        }
        if (error) {
          return (
            <div>
              <p>Error: {error}</p>
            </div>
          );
        }

    return user ? children : <Navigate to="/" />;
}

export default PrivateRoute;