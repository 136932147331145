import { React, useEffect, useState } from 'react';
import Avatars from './Avatars';
import ProgressBar from './ProgressBar';
import { Link } from "react-router-dom";
import { getProjectTasks } from './firebase';

function ProjectCard(props) {

    const [tasks, setTasks] = useState([]);

    useEffect(() => {
        if (props.project !== '') {
            getProjectTasks(props.project.id).then(r => {
                setTasks(r);
            });
        }
    }, [props]);

    const cust_agent = () => {
        let cust_agent = [];
        cust_agent.push(props.project.customer_id);
        cust_agent.push(props.project.agent_id);
        return cust_agent;
    }

    const count_completed_tasks = () => {
        let completed_tasks = 0;
        tasks.forEach((i) => {
            if (i.status === 'complete')
                completed_tasks++;
        });
        return completed_tasks;
    }

    const calc_percent_complete = () => {
        if (tasks.length === 0) {
            return 0;
        }
        else
            return (count_completed_tasks() / tasks.length) * 100;
    }

    const progress_bar_style = () => {
        let percent_complete = calc_percent_complete();

        if (percent_complete < 50) {
            return "progress-bar bg-danger";
        }

        if (percent_complete < 75) {
            return "progress-bar bg-warning";
        }

        if (percent_complete > 75) {
            return "progress-bar bg-success";
        }
    }

    return (
        <div>
            {props.project &&
                <div className="card card-project">
                    <ProgressBar width={calc_percent_complete() + "%"} style_class={progress_bar_style()} />

                    <div className="card-body">
                        <div className="dropdown card-options">
                            <Avatars users={cust_agent()} master_members_list={props.master_members_list} />
                        </div>
                        <div className="card-title">
                            <Link to={"/project/" + props.project.id}><h5 data-filter-by="text" className="H5-filter-by-text">{props.project.name}</h5></Link>
                            <span>{props.project.descr}</span>
                        </div>
                        <div className="card-meta d-flex justify-content-between">
                            <div className="d-flex align-items-center">
                                <i className="material-icons mr-1">playlist_add_check</i>
                                <span className="text-small">{count_completed_tasks()}/{tasks.length}</span>
                            </div>
                            <span className="text-small SPAN-filter-by-text" data-filter-by="text">{props.project.close_dt.toDate().toLocaleDateString('en-us')}</span>
                        </div>
                    </div>
                </div>
            }
        </div>
    )
}

export default ProjectCard;