import React from 'react';


function ProgressBar(props) {

    const comp_style = {
        width: props.width
    }

    return (
        <div className="progress">
            <div className={props.style_class} style={comp_style} role="progressbar"></div>
        </div>
    )
}

export default ProgressBar;
