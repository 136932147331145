import React from "react";
import { Alert } from "react-bootstrap";

export const FormErrors = ({ formErrors }) =>
    <div className='formErrors'>
        {Object.keys(formErrors).map((message, i) => {
            if (formErrors[message].length > 0) {
                return (
                    <div key={i}>
                        <Alert variant="danger">
                            {formErrors[message]}
                        </Alert>
                    </div>
                )
            } else {
                return '';
            }
        })}
    </div>