import { React } from 'react';
import ProgressBar from './ProgressBar';
import Avatars from "./Avatars";
import { Link } from "react-router-dom";


function TaskCard(props) {

    const handleEdit = (e) => {
        e.preventDefault();

        props.toggleEdit(props.task);
    }

    const progress_bar_style = () => {
        let percent_complete = calc_percent_complete();
        console.log(percent_complete);
        if (percent_complete < 50) {
            return "progress-bar bg-danger";
        }

        if (percent_complete < 75) {
            return "progress-bar bg-warning";
        }

        if (percent_complete > 75) {
            return "progress-bar bg-success";
        }
    }

    const calc_percent_complete = () => {
        if (props.task.checklist.length === 0) {
            return 0;
        }
        else
            //return (count_completed_tasks() / props.task.checklist.length) * 100;
            return 85;
    }

    const count_completed_checklist = () => {
        let completed_checklist = 0;
        props.task.checklist.forEach((i) => {
            if (i.complete === true)
                completed_checklist++;
        });
        return completed_checklist;
    }


    return (
        <div className="card card-task">
            <ProgressBar width={calc_percent_complete() + "%"} style_class={progress_bar_style()} />
            <div className="card-body">
                <div className="card-title">
                    <Link to={"/project/" + props.project_id + "/tasks/" + props.task.id}><h6 data-filter-by="text" className="H6-filter-by-text">{props.task.name}</h6></Link>
                    <span className="text-small">Due Date: {props.task.due_dt.toDate().toLocaleDateString('en-us')}</span>
                </div>
                <div className="card-meta">
                    {props.team &&
                        <Avatars users={props.team.members} master_members_list={props.master_members_list} />
                    }
                    <div className="d-flex align-items-center">
                        <i className="material-icons">playlist_add_check</i>
                        <span>{count_completed_checklist()}/{props.task.checklist.length}</span>
                    </div>
                    <div className="dropdown card-options show">
                        <button onClick={e => handleEdit(e)} className="btn-options" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded={true}>
                            <i className="material-icons">edit</i>
                        </button>
                    </div>
                    <div className="dropdown card-options">
                        <button onClick={e => props.delete(props.task.id)} className="btn-options" type="button" id="task-delete" aria-expanded="false">
                            <i className="material-icons">delete</i>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );

}

export default TaskCard;