import { React, useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { getTeam, getTeamProjects } from './firebase';
import Avatars from './Avatars';
// import ProjectCard from './ProjectCard';
import { Modal, Button } from "react-bootstrap";
import { addNewTeam } from './firebase';
import NewProject from './NewProject';
import ProjectCard from "./ProjectCard";

function Team(props) {
    const id = useParams();
    const [team, setTeam] = useState();
    const [currentView, setCurrentView] = useState("projects");
    const navigate = useNavigate();
    const default_avatar_url = "https://firebasestorage.googleapis.com/v0/b/customer-portal-shawgo.appspot.com/o/avatars%2Fdefault_user.jpg?alt=media&token=6ed7a745-44a1-4aa5-bafe-638339cc9da2";
    const [member_profiles, setMemberProfiles] = useState([]);
    const [showAddMembers, setShowAddMembers] = useState(false);
    const [updated_members, setUpdatedMembers] = useState([]);
    const [filteredMemberList, setFilteredMemberList] = useState([]);
    const [memberFilterText, setMemberFilterText] = useState("");
    const [team_refresh, setTeamRefresh] = useState(false);
    const [showNewProject, setShowNewProject] = useState(false);
    const [projects, setProjects] = useState([]);
    const [projects_filtered, setProjectsFiltered] = useState([]);
    const [projects_filtered_text, setProjectsFilteredText] = useState("");

    useEffect(() => {
        if (typeof id !== 'undefined') {
            getTeam(id.id).then((ret) => {
                if (ret !== 0) {
                    setTeam(ret);
                    setUpdatedMembers(ret.members);
                }
                else {
                    navigate("/notfound");
                }
            })

            getTeamProjects(id.id).then((ret) => {
                setProjects(ret, setProjectsFiltered(ret));
            });

        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [team_refresh]);

    const handleProjectFilterChange = (e) => {
        setProjectsFilteredText(e.target.value);
        let filter_projects = projects;
        if (e.target.value.length === 0)
            setProjectsFiltered(projects);
        else
            setProjectsFiltered(filter_projects.filter(proj => proj.name.toLowerCase().indexOf(e.target.value.toLowerCase()) !== -1 || proj.descr.toLowerCase().indexOf(e.target.value.toLowerCase()) !== -1));
    }

    const handleShowNewProject = (e) => {
        e.preventDefault();
        setShowNewProject(true);
    }

    const handleHideNewProject = () => {
        setShowNewProject(false);
    }

    const isAddedtoTeam = (id) => {
        //check if a uid is added to the AddTeamMembers array. and return bool.
        //console.log(updated_members);
        //console.log(id);
        //let index = -1;
        let index = updated_members.findIndex(element => element === id);
        //console.log(id);
        //console.log(index);
        //console.log(new_team.members);

        if (index === -1)
            return false;
        else
            return true;
    }

    const handleUpdateTeamMembers = (e) => {
        e.preventDefault();
        team.members = updated_members;
        addNewTeam(team).then(() => {
            setShowAddMembers(false);
            setTeamRefresh(!team_refresh);
            setMemberProfiles(getMemberProfiles());
        });
    }

    const handleAddMemberChanged = (event) => {
        const target = event.target;
        //const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        //console.log(name);
        //console.log(value);

        let index = updated_members.findIndex(element => element === name);
        let user = props.master_members_list.find(element => element.uid === name);

        //console.log(user);

        if (index === -1) {
            //Add User to Team
            //console.log(index);
            let new_members = [...updated_members];
            //console.log(new_members);
            new_members.push(user.uid);
            //console.log(new_members);
            setUpdatedMembers(new_members);

            return true;
        }
        else {
            //remove user from team.
            //console.log(index);
            let new_members = [...updated_members];
            new_members.splice(index, 1);
            setUpdatedMembers(new_members);
        }
    }

    const handleTeamMemberFilterChange = (e) => {
        setMemberFilterText(e.target.value);
        //console.log(e.target.value);
        let filter_members = props.master_members_list;
        if (e.target.value.length === 0)
            setFilteredMemberList(filter_members);
        else
            setFilteredMemberList(filter_members.filter(user => user.first_name.toLowerCase().indexOf(e.target.value.toLowerCase()) !== -1 || user.last_name.toLowerCase().indexOf(e.target.value.toLowerCase()) !== -1));
    }

    const getMemberProfiles = () => {
        let temp_member_list = [];
        team.members.forEach(element => {
            //console.log(element);
            const user = props.master_members_list.find(e => e.uid === element);
            temp_member_list.push({
                uid: user.uid,
                first_name: user.first_name,
                last_name: user.last_name,
                avatar_url: user.avatar_url,
                title: user.title
            });
        })
        return temp_member_list;
    }

    const handleChangeCurrentView = (e, view) => {
        e.preventDefault();
        if (view === 'members') {
            setMemberProfiles(getMemberProfiles());
        }
        setCurrentView(view);
    }

    const handleShowAddMemberClose = (e) => {
        e.preventDefault();
        setShowAddMembers(false);
    }

    const handleShowAddmember = (e) => {
        e.preventDefault();
        setFilteredMemberList(props.master_members_list);
        setMemberFilterText("");
        setShowAddMembers(true);
    }

    return (
        <div className="main-container">
            {team &&
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-xl-10 col-lg-11">
                            <div className="page-header">
                                <h1>{team.name}</h1>
                                <p className="lead">{team.descr}</p>
                                <div className="d-flex align-items-center">
                                    <Avatars users={team.members} master_members_list={props.master_members_list} align="center" />
                                </div>
                            </div>
                            <hr />
                            <ul className="nav nav-tabs nav-fill" role="tablist">
                                <li className="nav-item">
                                    <a className={currentView === 'projects' ? "nav-link active" : "nav-link"} onClick={e => handleChangeCurrentView(e, 'projects')} data-toggle="tab" href="#projects" role="tab" aria-controls="projects" aria-selected={currentView === 'projects' ? 'true' : 'false'}>Projects</a>
                                </li>
                                <li className="nav-item">
                                    <a className={currentView === 'members' ? "nav-link active" : "nav-link"} onClick={e => handleChangeCurrentView(e, 'members')} data-toggle="tab" href="#members" role="tab" aria-controls="members" aria-selected={currentView === 'members' ? 'true' : 'false'}>Members</a>
                                </li>
                            </ul>
                            <div className="tab-content">
                                {currentView === 'projects' &&
                                    <div className={currentView === 'projects' ? "tab-pane fade active show" : "tab-pane fade"} id="projects" role="tabpanel" data-filter-list="content-list-body">
                                        <div className="content-list">
                                            <div className="row content-list-head">
                                                <div className="col-auto">
                                                    <h3>Projects</h3>
                                                    <button onClick={e => handleShowNewProject(e)} className="btn btn-round" data-toggle="modal" data-target="#project-add-modal">
                                                        <i className="material-icons">add</i>
                                                    </button>
                                                </div>
                                                <form className="col-md-auto">
                                                    <div className="input-group input-group-round">
                                                        <div className="input-group-prepend">
                                                            <span className="input-group-text">
                                                                <i className="material-icons">filter_list</i>
                                                            </span>
                                                        </div>
                                                        <input type="search" value={projects_filtered_text} onChange={e => handleProjectFilterChange(e)} className="form-control filter-list-input" placeholder="Filter projects" aria-label="Filter Projects" />
                                                    </div>
                                                </form>
                                            </div>
                                            <div className="content-list-body row filter-list-">
                                                {
                                                    projects_filtered.map((project) => (
                                                        <div className="col-md-6" key={project.id}>
                                                            <ProjectCard project={project} master_members_list={props.master_members_list} />
                                                        </div>
                                                    ))
                                                }
                                            </div>
                                        </div>
                                        <NewProject show={showNewProject} onClose={handleHideNewProject} master_members_list={props.master_members_list} />
                                    </div>
                                }
                            </div>





                            <div className={currentView === 'members' ? "tab-pane fade active show" : "tab-pane fade"} id="members" role="tabpanel" data-filter-list="content-list-body">
                                <div className="content-list">
                                    <div className="row content-list-head">
                                        <div className="col-auto">
                                            <h3>Members</h3>
                                            <>{props.profile.role === 'admin' &&
                                                <button className="btn btn-round" data-toggle="modal" onClick={e => handleShowAddmember(e)}>
                                                    <i className="material-icons">add</i>
                                                </button>
                                            }</>
                                        </div>
                                    </div>
                                    <div className="content-list-body row filter-list-1637782212775">
                                        {member_profiles.map((member) => (
                                            <div className="col-6" key={member.uid}>
                                                <div className="media media-member">
                                                    <img alt="Claire Connors" src={member.avatar_url !== "" ? member.avatar_url : default_avatar_url} className="avatar avatar-lg" />
                                                    <div className="media-body">
                                                        <h6 className="mb-0 H6-filter-by-text" data-filter-by="text">{member.first_name + ' ' + member.last_name}</h6>
                                                        <span data-filter-by="text" className="text-body SPAN-filter-by-text">{member.title}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        ))
                                        }
                                    </div>
                                </div>

                                <Modal show={showAddMembers} animation={false}>
                                    <Modal.Header>
                                        <Modal.Title>Manage Team Members</Modal.Title>
                                    </Modal.Header>
                                    {updated_members &&
                                        <Modal.Body>
                                            <div className="modal-body">
                                                <div className="tab-content">
                                                    <div className="tab-pane fade show active" id="team-add-members" role="tabpanel">
                                                        <div className="users-manage" data-filter-list="form-group-users">
                                                            <div className="mb-3">
                                                                <Avatars users={updated_members} master_members_list={props.master_members_list} align="center" />
                                                            </div>
                                                            <div className="input-group input-group-round">
                                                                <div className="input-group-prepend">
                                                                    <span className="input-group-text">
                                                                        <i className="material-icons">filter_list</i>
                                                                    </span>
                                                                </div>
                                                                <input type="search" value={memberFilterText} onChange={e => handleTeamMemberFilterChange(e)} className="form-control filter-list-input" placeholder="Filter members" aria-label="Filter Members" />
                                                            </div>
                                                            <div className="form-group-users filter-list-1633658121439">
                                                                {filteredMemberList &&
                                                                    filteredMemberList.map((member) => (
                                                                        <div className="custom-control custom-checkbox" name={member.uid} key={member.uid}>
                                                                            <input type="checkbox" onChange={e => handleAddMemberChanged(e)} className="custom-control-input" name={member.uid} id={member.uid} checked={isAddedtoTeam(member.uid)} />
                                                                            <label className="custom-control-label" htmlFor={member.uid}>
                                                                                <span className="d-flex align-items-center">
                                                                                    <img alt={member.first_name + ' ' + member.last_name} src={member.avatar_url} className="avatar mr-2" />
                                                                                    <span className="h6 mb-0 SPAN-filter-by-text" data-filter-by="text">{member.first_name + ' ' + member.last_name}</span>
                                                                                </span>
                                                                            </label>
                                                                        </div>
                                                                    ))
                                                                }



                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </Modal.Body>
                                    }
                                    <Modal.Footer>
                                        <Button variant="secondary" onClick={e => handleShowAddMemberClose(e)}>
                                            Close
                                        </Button>
                                        <Button variant="primary" onClick={e => handleUpdateTeamMembers(e)}>
                                            Update Members
                                        </Button>
                                    </Modal.Footer>
                                </Modal>

                            </div>




                        </div>
                    </div>
                </div>
            }
        </div>
    )
}

export default Team;