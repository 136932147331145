import "./Dashboard.css";
import React, { useState, useEffect } from "react";
import Teamcard from "./Teamcard";
import { Modal, Button } from "react-bootstrap";
import { getTeams, addNewTeam, getMyProjects } from "./firebase";
import { FormErrors } from "./FormErrors";
import Avatars from "./Avatars";
import { v4 } from 'uuid';
import ProjectCard from "./ProjectCard";

function Dashboard(props) {
  const AddTeamErrors = { team_name: '', team_descr: '' };
  const [teams, setTeams] = useState([]);
  const [showAddTeam, setShowAddTeam] = useState(false);
  const [showTeamDetails, setShowTeamDetails] = useState(true);
  const [showTeamMembers, setShowTeamMembers] = useState(false);
  const [currentView, setCurrentView] = useState("projects");
  const [new_team, setNewTeam] = useState({
    "id": v4(),
    "name": "",
    "descr": "",
    "members": []
  });
  const [AddTeamformErrors, setAddTeamFormErrors] = useState(AddTeamErrors);
  const [filteredMemberList, setFilteredMemberList] = useState([]);
  const [teamFilterText, setTeamFilterText] = useState("");
  const [teams_filter_text, setTeamsFilterText] = useState("");
  const [teams_filtered, setTeamsFiltered] = useState([]);
  const [team_refresh, setTeamRefresh] = useState(false);
  const [projects, setProjects] = useState([]);
  const [projects_filtered, setProjectsFiltered] = useState([]);
  const [projects_filtered_text, setProjectsFilteredText] = useState("");


  useEffect(() => {
    getTeams().then(r => {
      //console.log(r);
      setTeams(r, setTeamsFiltered(r));
    });
    getMyProjects().then(r => {
      console.log(r);
      setProjects(r, setProjectsFiltered(r));
    });
  }, [team_refresh]);

  const handleTeamsFilterTextChange = (e) => {
    setTeamsFilterText(e.target.value);
    //console.log(e.target.value);
    if (e.target.value.length === 0)
      setTeamsFiltered(teams);
    else
      setTeamsFiltered(teams.filter(team => team.name.toLowerCase().indexOf(e.target.value.toLowerCase()) !== -1));

  }

  const handleAddMemberChanged = (event) => {
    const target = event.target;
    //const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    //console.log(name);
    //console.log(value);

    let index = new_team.members.findIndex(element => element === name);
    let user = props.master_members_list.find(element => element.uid === name);

    //console.log(user);

    if (index === -1) {
      //Add User to Team
      //console.log(index);
      let new_members = [...new_team.members];
      //console.log(new_members);
      new_members.push(user.uid);
      //console.log(new_members);
      setNewTeam((prevState) => ({
        ...prevState, members: new_members

      }))

      return true;
    }
    else {
      //remove user from team.
      //console.log(index);
      let new_members = [...new_team.members];
      new_members.splice(index, 1);
      setNewTeam((prevState) => ({
        ...prevState,
        members: new_members
      }));
    }
  }

  const isAddedtoTeam = (id) => {
    //check if a uid is added to the AddTeamMembers array. and return bool.
    //console.log(new_team.members);
    //console.log(id);
    //let index = -1;
    let index = new_team.members.findIndex(element => element === id);
    //console.log(id);
    //console.log(index);
    //console.log(new_team.members);

    if (index === -1)
      return false;
    else
      return true;
  }

  const handleShowAddTeam = () => {
    setShowAddTeam(true);
    setFilteredMemberList(props.master_members_list);
    setTeamFilterText("");
  };

  const handleTeamMemberFilterChange = (e) => {
    setTeamFilterText(e.target.value);
    //console.log(e.target.value);
    let filter_members = props.master_members_list;
    if (e.target.value.length === 0)
      setFilteredMemberList(filter_members);
    else
      setFilteredMemberList(filter_members.filter(user => user.first_name.toLowerCase().indexOf(e.target.value.toLowerCase()) !== -1 || user.last_name.toLowerCase().indexOf(e.target.value.toLowerCase()) !== -1));
  }

  const handleProjectFilterChange = (e) => {
    setProjectsFilteredText(e.target.value);
    let filter_projects = projects;
    if (e.target.value.length === 0)
      setProjectsFiltered(projects);
    else
      setProjectsFiltered(filter_projects.filter(proj => proj.name.toLowerCase().indexOf(e.target.value.toLowerCase()) !== -1 || proj.descr.toLowerCase().indexOf(e.target.value.toLowerCase()) !== -1));
  }

  const handleShowAddTeamClose = () => {
    setShowAddTeam(false);
    setShowTeamDetails(true);
    setShowTeamMembers(false);
    setAddTeamFormErrors(AddTeamErrors);
    setNewTeam({
      "id": v4(),
      "name": "",
      "descr": "",
      "members": []
    }
    );
  }
  const handleShowTeamDetails = (e) => {
    e.preventDefault();
    setShowTeamDetails(true);
    setShowTeamMembers(false);
  };

  const handleViewChange = (e, v) => {
    e.preventDefault();
    setCurrentView(v);
  }
  const handleShowTeamMembers = (e) => {
    e.preventDefault();
    setShowTeamDetails(false);
    setShowTeamMembers(true);
  };
  const newTeamToggle = (t) => {
    if (t === 'details')
      return showTeamDetails ? "tab-pane fade show active" : "tab-pane";
    else
      return showTeamMembers ? "tab-pane fade show active" : "tab-pane";
  }

  const handleNewTeamInputChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    //console.log(value);
    if (name === "team-name") {
      setNewTeam((prevState) => ({
        ...prevState,
        name: value
      }));
    }

    if (name === "team-description") {
      setNewTeam((prevState) => ({
        ...prevState,
        descr: value
      }));
    }
  }

  const handleAddTeam = () => {
    if (validateForm()) {
      //form valid... do save stuff here...
      addNewTeam(new_team).then(r => {
        //console.log("new team added.");
        getTeams().then(r => {
          //console.log("teams refreshed...");
          handleShowAddTeamClose();
          setTeamRefresh(!team_refresh);
        });
      })
    }
  }

  const validateForm = () => {
    let form_valid = true;
    let errors = AddTeamErrors;

    if (new_team.name.length === 0) {
      errors.team_name = 'Team Name is a Required Field!';
      form_valid = false;
    }

    if (new_team.descr.length === 0) {
      errors.team_descr = 'Team Description is a Required Field!';
      form_valid = false;
    }

    setAddTeamFormErrors(errors);

    return form_valid;
  }

  return (
    <div className="main-container">
      {props.profile &&
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-11 col-xl-10">
              <div className="page-header mb-4">
                <div className="media">
                  <img alt="avatar" src={props.profile.avatar_url} className="avatar avatar-lg mt-1" />
                  <div className="media-body ml-3">
                    <h1 className="mb-0">{props.profile.first_name + ' ' + props.profile.last_name}</h1>
                    <p className="lead">{props.profile.title}</p>
                  </div>
                </div>
              </div>
              <ul className="nav nav-tabs nav-fill" role="tablist">
                <li className="nav-item" key="teams">
                  <a className={currentView === 'teams' ? 'nav-link active' : 'nav-link'} onClick={e => handleViewChange(e, 'teams')} data-toggle="tab" href="#teams" role="tab" aria-controls="teams" aria-selected={currentView === 'teams' ? 'true' : 'false'}>Teams</a>
                </li>
                <li className="nav-item" key="projects">
                  <a className={currentView === 'projects' ? 'nav-link active' : 'nav-link'} onClick={e => handleViewChange(e, 'projects')} data-toggle="tab" href="#projects" role="tab" aria-controls="projects" aria-selected={currentView === 'projects' ? 'true' : 'false'}>Projects</a>
                </li>
              </ul>
              {currentView === 'teams' &&
                <div className="tab-content">
                  <div className="row content-list-head">
                    <div className="col-auto">
                      <h3>Teams</h3>
                      <>{props.profile.role === 'admin' &&
                        <button className="btn btn-round" data-toggle="modal" data-target="#team-add-modal" onClick={e => handleShowAddTeam()}>
                          <i className="material-icons">add</i>
                        </button>
                      }
                      </>

                    </div>
                    <form className="col-md-auto">
                      <div className="input-group input-group-round">
                        <div className="input-group-prepend">
                          <span className="input-group-text">
                            <i className="material-icons">filter_list</i>
                          </span>
                        </div>
                        <input type="search" className="form-control filter-list-input" value={teams_filter_text} onChange={e => handleTeamsFilterTextChange(e)} placeholder="Filter teams" aria-label="Filter teams" />
                      </div>
                    </form>
                  </div>
                  <div className="content-list-body row filter-list-1636672740846">
                    {
                      teams_filtered.map((team) => (
                        <div className="col-md-6" key={team.name}>
                          <Teamcard data={team} master_members_list={props.master_members_list} />
                        </div>
                      ))
                    }
                  </div>
                </div>
              }
              {currentView === 'projects' &&
                <div className="tab-content">
                  <div className="row content-list-head">
                    <div className="col-auto">
                      <h3>Projects</h3>
                    </div>
                    <form className="col-md-auto">
                      <div className="input-group input-group-round">
                        <div className="input-group-prepend">
                          <span className="input-group-text">
                            <i className="material-icons">filter_list</i>
                          </span>
                        </div>
                        <input type="search" value={projects_filtered_text} onChange={e => handleProjectFilterChange(e)} className="form-control filter-list-input" placeholder="Filter projects" aria-label="Filter projects" />
                      </div>
                    </form>
                  </div>
                  <div className="content-list-body row filter-list-">
                    {
                      projects_filtered.map((project) => (
                        <div className="col-md-6" key={project.id}>
                          <ProjectCard project={project} master_members_list={props.master_members_list} />
                        </div>
                      ))
                    }
                  </div>
                </div>
              }

              {currentView === 'tasks' &&
                <div className="tab-content">
                  <div className="row content-list-head">
                    <div className="col-auto">
                      <h3>Tasks</h3>
                    </div>
                    <form className="col-md-auto">
                      <div className="input-group input-group-round">
                        <div className="input-group-prepend">
                          <span className="input-group-text">
                            <i className="material-icons">filter_list</i>
                          </span>
                        </div>
                        <input type="search" className="form-control filter-list-input" placeholder="Filter tasks" aria-label="Filter tasks" />
                      </div>
                    </form>

                  </div>
                </div>
              }

              <Modal show={showAddTeam} animation={false}>
                <Modal.Header>
                  <Modal.Title>New Team</Modal.Title>
                </Modal.Header>
                {new_team &&
                  <Modal.Body>
                    <ul className="nav nav-tabs nav-fill" role="tablist">
                      <li className="nav-item" key="team-add-details">
                        <a className={showTeamDetails ? "nav-link active" : "nav-link"} id="team-add-details-tab" onClick={e => handleShowTeamDetails(e)} data-toggle="tab" href="#team-add-details" role="tab" aria-controls="team-add-details" aria-selected={showTeamDetails}>Details</a>
                      </li>
                      <li className="nav-item" key="team-add-members">
                        <a className={showTeamMembers ? "nav-link active" : "nav-link"} id="team-add-members-tab" onClick={e => handleShowTeamMembers(e)} data-toggle="tab" href="#team-add-members" role="tab" aria-controls="team-add-members" aria-selected={showTeamMembers}>Members</a>
                      </li>
                    </ul>
                    <div className="modal-body">
                      <FormErrors formErrors={AddTeamformErrors} />
                      <div className="tab-content">
                        <div className={newTeamToggle('details')} id="team-add-details" role="tabpanel">
                          <h6>Team Details - <small>{new_team.id}</small></h6>
                          <div className="form-group row align-items-center">
                            <label className="col-3">Name</label>
                            <input className="form-control col" type="text" value={new_team.name} onChange={e => handleNewTeamInputChange(e)} placeholder="Team name" name="team-name" />
                          </div>
                          <div className="form-group row">
                            <label className="col-3">Description</label>
                            <textarea className="form-control col" rows="3" value={new_team.descr} onChange={e => handleNewTeamInputChange(e)} placeholder="Team description" name="team-description"></textarea>
                          </div>
                        </div>
                        <div className={newTeamToggle('members')} id="team-add-members" role="tabpanel">
                          <div className="users-manage" data-filter-list="form-group-users">
                            <div className="mb-3">
                              <Avatars users={new_team.members} master_members_list={props.master_members_list} align="center" />
                            </div>
                            <div className="input-group input-group-round">
                              <div className="input-group-prepend">
                                <span className="input-group-text">
                                  <i className="material-icons">filter_list</i>
                                </span>
                              </div>
                              <input type="search" value={teamFilterText} onChange={k => handleTeamMemberFilterChange(k)} className="form-control filter-list-input" placeholder="Filter members" aria-label="Filter Members" />
                            </div>
                            <div className="form-group-users filter-list-1633658121439">
                              {filteredMemberList &&
                                filteredMemberList.map((member) => (
                                  <div className="custom-control custom-checkbox" name={member.uid} key={member.uid}>
                                    <input onChange={e => handleAddMemberChanged(e)} type="checkbox" className="custom-control-input" name={member.uid} id={member.uid} checked={isAddedtoTeam(member.uid)} />
                                    <label className="custom-control-label" htmlFor={member.uid}>
                                      <span className="d-flex align-items-center">
                                        <img alt={member.first_name + ' ' + member.last_name} src={member.avatar_url} className="avatar mr-2" />
                                        <span className="h6 mb-0 SPAN-filter-by-text" data-filter-by="text">{member.first_name + ' ' + member.last_name}</span>
                                      </span>
                                    </label>
                                  </div>
                                ))
                              }
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Modal.Body>
                }
                <Modal.Footer>
                  <Button variant="secondary" onClick={e => handleShowAddTeamClose(e)}>
                    Close
                  </Button>
                  <Button variant="primary" onClick={e => handleAddTeam(e)}>
                    Add Team
                  </Button>
                </Modal.Footer>
              </Modal>


            </div>
          </div>
        </div>
      }
    </div>
  );
}
export default Dashboard;
