import { React, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { getProject, getProjectTaskSub, GetAllTeams, addNewProjectTask, deleteTask, editTask } from './firebase';
import ProgressBar from './ProgressBar';
import Avatars from './Avatars';
import TaskCard from './TaskCard';
import { Modal, Button } from "react-bootstrap";
import Flatpickr from "react-flatpickr";
import { v4 } from 'uuid';
import { FormErrors } from "./FormErrors";
import ActivityCard from './ActivityCard';


function Project(props) {

    const [project, setProject] = useState();
    const [tasks, setTasks] = useState([]);
    const id = useParams();
    const [toggleLaunch, setToggleLaunch] = useState(false);
    const [toggleAddTask, setToggleAddTask] = useState(false);
    const [toggleEditTask, setToggleEditTask] = useState(false);
    const [name, setName] = useState("");
    const [descr, setDescr] = useState("");
    const [category, setCategory] = useState("");
    const [team, setTeam] = useState("");
    const [due_dt, setDueDate] = useState(new Date());
    const [all_teams, setAllTeams] = useState([]);
    const [teams, setTeams] = useState([]);
    const [sequence, setSequence] = useState(10);
    const AddTaskErrors = { task_name: '', task_descr: '', task_team: '', task_cat: '' };
    const [AddTaskformErrors, setAddTaskFormErrors] = useState(AddTaskErrors);
    const task_categories = ['Lender', 'Broker', 'Homeowner', 'Attorney/Settlement Co.', 'Seller', 'Buyer'];
    const [currentView, setCurrentView] = useState("tasks");
    const [edit_task_id, setEditTaskId] = useState("");
    const [edit_name, setEditName] = useState("");
    const [edit_descr, setEditDescr] = useState("");
    const [edit_category, setEditCategory] = useState("");
    const [edit_due_dt, setEditDueDate] = useState(new Date());
    const [edit_team, setEditTeam] = useState("");
    const [edit_sequence, setEditSequence] = useState(10);
    const [toggleDeleteTask, setToggleDeleteTask] = useState(false);
    const [delete_task_id, setDeleteTaskID] = useState("");

    //const [filter_task_test, setFilterTaskText] = useState("");

    const getProjectData = (doc) => {
        console.log("Current data: ", doc.data());
        setProject(doc.data());
    }

    const getProjectTasksData = (querySnapshot) => {
        const tasks = [];
        querySnapshot.forEach((doc) => {
            tasks.push(doc.data());
        });

        //console.log(tasks);
        setTasks(tasks);
    }

    const catHasTasks = (cat) => {

        let count = tasks.filter(task => task.category === cat).length;

        return count > 0 ? true : false;
    }

    const handleToggleEditTask = (task) => {
        let temp_teams = [];
        project.teams.forEach((id) => {
            let t = all_teams.find(element => element.id === id);
            //console.log(t);
            temp_teams.push(t);
        });
        setTeams(temp_teams);

        setToggleEditTask(true);
        setEditTaskId(task.id);
        setEditName(task.name);
        setEditSequence(task.seq);
        setEditDescr(task.descr);
        setEditDueDate(new Date(task.due_dt));
        setEditCategory(task.category);
        setEditTeam(task.team);

    }

    const handleEditTaskClose = (e) => {
        e.preventDefault();
        setToggleEditTask(false);

    }

    const handleEditTask = (e) => {
        e.preventDefault();

        editTask(id.id, edit_task_id, edit_name, edit_sequence, edit_due_dt, edit_category, edit_team, edit_descr).then(() => {
            setToggleEditTask(false);
        });

    }

    const handleChangeCurrentView = (e, view) => {
        e.preventDefault();
        setCurrentView(view);
    }

    const validateAddTaskForm = () => {
        let form_valid = true;
        let errors = AddTaskErrors;

        if (name.length === 0) {
            errors.task_name = 'Task Name is a Required Field!';
            form_valid = false;
        }

        if (descr.length === 0) {
            errors.task_descr = 'Task Description is a Required Field!';
            form_valid = false;
        }

        if (category === "") {
            errors.task_cat = 'Task Category is a Required Field!';
            form_valid = false;
        }

        if (team === "") {
            errors.task_team = 'Task Team is a Required Field!';
            form_valid = false;
        }

        setAddTaskFormErrors(errors);

        return form_valid;
    }

    const calc_percent_complete = () => {
        if (tasks.length === 0) {
            return 59;
        }
        else
            //return (count_completed_tasks() / tasks.length) * 100;
            return 85;
    }

    const TaskDelete = (e) => {
        e.preventDefault();
        deleteTask(project.id, delete_task_id).then(() => {
            setToggleDeleteTask(false);
            setDeleteTaskID("");
        });
    }

    const handleToggleDeleteTask = (task_id) => {
        setDeleteTaskID(task_id);
        setToggleDeleteTask(true);
    }

    const handleDeleteTaskCancel = () => {
        setToggleDeleteTask(false);
        setDeleteTaskID("");
    }

    const handleTaskFormInputChange = (e) => {
        const name = e.target.name;
        const value = e.target.value;

        if (name === "task-name") {
            setName(value);
        }

        if (name === "task-description") {
            setDescr(value);
        }

        if (name === "task-category") {
            setCategory(value);
        }

        if (name === "task-team") {
            setTeam(value);
        }

        if (name === "task-seq") {
            setSequence(value);
        }

    }

    const handleTaskEditFormInputChange = (e) => {
        const name = e.target.name;
        const value = e.target.value;

        if (name === "edit-task-name") {
            setEditName(value);
        }

        if (name === "edit-task-description") {
            setEditDescr(value);
        }

        if (name === "edit-task-category") {
            setEditCategory(value);
        }

        if (name === "edit-task-team") {
            setEditTeam(value);
        }

        if (name === "edit-task-seq") {
            setEditSequence(value);
        }

    }

    const handleToggleLaunch = (e) => {
        e.preventDefault();

        setToggleLaunch(true);
    }

    const handleToggleAddTask = (e) => {
        e.preventDefault();

        setToggleAddTask(true);

        let temp_teams = [];
        project.teams.forEach((id) => {
            let t = all_teams.find(element => element.id === id);
            //console.log(t);
            temp_teams.push(t);
        });
        setTeams(temp_teams);
    }

    const handleShowLaunchClose = (e) => {
        e.preventDefault();
        setToggleLaunch(false);
    }

    const getTaskTeamMembers = (id) => {
        //console.log(id);

        let t = all_teams.find(element => element.id === id);
        //console.log(t);
        //console.log(t.members);
        return t;
    }

    const handleLaunch = (e) => {
        e.preventDefault();
        //do stuff here to load tasks from workflow template onto this project...
    }

    const handleShowTaskClose = (e) => {
        e.preventDefault();
        setToggleAddTask(false);
        setAddTaskFormErrors(AddTaskErrors);

    }

    const handleAddTask = (e) => {
        e.preventDefault();
        if (validateAddTaskForm()) {
            addNewProjectTask(id.id, v4(), name, descr, team, category, sequence, due_dt).then(() => {
                setToggleAddTask(false);
            });
        }
    }

    const cust_agent = () => {
        let cust_agent = [];
        cust_agent.push(project.customer_id);
        cust_agent.push(project.agent_id);
        return cust_agent;
    }

    const progress_bar_style = () => {
        let percent_complete = calc_percent_complete();

        if (percent_complete < 50) {
            return "progress-bar bg-danger";
        }

        if (percent_complete < 75) {
            return "progress-bar bg-warning";
        }

        if (percent_complete > 75) {
            return "progress-bar bg-success";
        }
    }

    const count_completed_tasks = () => {
        let completed_tasks = 0;
        tasks.forEach((i) => {
            if (i.status === 'complete')
                completed_tasks++;
        });
        return completed_tasks;
    }

    const handleSequenceInc = (e) => {
        e.preventDefault();

        setSequence(sequence + 10);
    }

    const handleSequenceDec = (e) => {
        e.preventDefault();

        setSequence(sequence - 10);
    }

    const handleEditSequenceInc = (e) => {
        e.preventDefault();

        setEditSequence(edit_sequence + 10);
    }

    const handleEditSequenceDec = (e) => {
        e.preventDefault();

        setEditSequence(edit_sequence - 10);
    }

    useEffect(() => {
        //console.log('useEffect called... ');
        let f = getProject(id, getProjectData);
        //console.log(f);

        let g = getProjectTaskSub(id, getProjectTasksData);

        GetAllTeams().then(r => {
            //console.log(r);
            setAllTeams(r);
        });

        //console.log(g);

        return () => {
            //console.log('unmounting the project component.');
            f();
            g();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className="main-container">
            {project &&
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-11 col-xl-10">
                            <div className="page-header">
                                <h1>{project.name}</h1>
                                <p className="lead">{project.descr}</p>
                                <Avatars users={cust_agent()} master_members_list={props.master_members_list} />
                                <div>
                                    <ProgressBar width={calc_percent_complete() + "%"} style_class={progress_bar_style()} />
                                    <div className="d-flex justify-content-between text-small">
                                        <div className="d-flex align-items-center">
                                            <i className="material-icons">playlist_add_check</i>
                                            <span>{count_completed_tasks()}/{tasks.length}</span>
                                        </div>
                                        <span><strong>Closing Date:</strong> {project.close_dt.toDate().toLocaleDateString('en-us')}</span>
                                    </div>
                                    <div className="d-flex justify-content-between text-small">
                                        <div className="d-flex align-items-center">
                                            <span><strong>Status:</strong> {project.status}</span>
                                        </div>
                                    </div>
                                    <div className="d-flex justify-content-between text-small">
                                        <div className="d-flex align-items-center">
                                            <span><strong>Workflow Template:</strong> {project.workflow_template_id}</span>
                                            <div className="col-auto">
                                                <button onClick={e => handleToggleLaunch(e)} className="btn btn-round" data-toggle="modal" data-target="#task-add-modal"><i className="material-icons">rocket_launch</i></button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <ul class="nav nav-tabs nav-fill" role="tablist">
                                <li class="nav-item">
                                    <a className={currentView === 'tasks' ? "nav-link active" : "nav-link"} data-toggle="tab" onClick={e => handleChangeCurrentView(e, 'tasks')} href="#task" role="tab" aria-controls="task" aria-selected={currentView === 'tasks' ? 'true' : 'false'}>Task</a>
                                </li>
                                <li class="nav-item">
                                    <a className={currentView === 'files' ? "nav-link active" : "nav-link"} data-toggle="tab" onClick={e => handleChangeCurrentView(e, 'files')} href="#files" role="tab" aria-controls="files" aria-selected={currentView === 'files' ? 'true' : 'false'}>Files</a>
                                </li>
                                <li class="nav-item">
                                    <a className={currentView === 'activity' ? "nav-link active" : "nav-link"} data-toggle="tab" onClick={e => handleChangeCurrentView(e, 'activity')} href="#activity" role="tab" aria-controls="activity" aria-selected={currentView === 'activity' ? 'true' : 'false'}>Activity</a>
                                </li>
                            </ul>
                            <div className="tab-content">
                                {currentView === 'tasks' &&
                                    <div className="tab-pane fade show active" id="tasks" role="tabpanel" data-filter-list="card-list-body">
                                        <div className="row content-list-head">
                                            <div className="col-auto">
                                                <h3>Tasks</h3>
                                                <button onClick={e => handleToggleAddTask(e)} className="btn btn-round" data-toggle="modal" data-target="#task-add-modal">
                                                    <i className="material-icons">add</i>
                                                </button>
                                            </div>
                                        </div>
                                        <div className="content-list-body">
                                            {
                                                task_categories.filter(catHasTasks).map((cat) => (
                                                    <div key={cat} className="card-list">
                                                        <div key={cat} className="card-list-head">
                                                            <h6>{cat}</h6>
                                                        </div>
                                                        <div className="card-list-body filter-list-1639695259173">
                                                            {
                                                                tasks.filter(task => task.category === cat).map((task) => (
                                                                    <TaskCard key={task.id} toggleEdit={handleToggleEditTask} project_id={project.id} task={task} master_members_list={props.master_members_list} team={getTaskTeamMembers(task.team)} delete={handleToggleDeleteTask} />
                                                                ))
                                                            }

                                                        </div>
                                                    </div>
                                                ))
                                            }

                                        </div>
                                    </div>
                                }
                                {currentView === 'files' &&
                                    <div className="tab-pane fade show active" id="files" role="tabpanel" data-filter-list="card-list-body">
                                        <div className="row content-list-head">
                                            <div className="col-auto">
                                                <h3>Files</h3>
                                            </div>
                                        </div>
                                        <div className="content-list-body">
                                            Under Construction...
                                        </div>
                                    </div>
                                }
                                {currentView === 'activity' &&
                                    <div className="tab-pane fade show active" id="activity" role="tabpanel" data-filter-list="card-list-body">
                                        <div className="row content-list-head">
                                            <div className="col-auto">
                                                <h3>Activity</h3>
                                            </div>
                                        </div>
                                        <div className="content-list-body">
                                            <ol class="list-group list-group-activity filter-list-1641391464192">
                                                {
                                                project.activity.map((act) => (
                                                    <ActivityCard master_members_list={props.master_members_list} activity={act}  />
                                                ))
}
                                            </ol>
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                    <Modal show={toggleLaunch} animation={false}>
                        <Modal.Header>
                            <Modal.Title>Launch Project Tasks</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            launch codes go here....
                        </Modal.Body>

                        <Modal.Footer>
                            <Button variant="secondary" onClick={e => handleShowLaunchClose(e)}>
                                Close
                            </Button>
                            <Button variant="primary" onClick={e => handleLaunch(e)}>
                                Launch
                            </Button>
                        </Modal.Footer>
                    </Modal>

                    <Modal show={toggleEditTask} animation={false}>
                        <Modal.Header>
                            <Modal.Title>Edit Task</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                        <div className="tab-content">
                                <FormErrors formErrors={AddTaskformErrors} />
                                <div className="tab-pane fade active show" id="task-add-details" role="tabpanel">
                                    <h6>Task Details</h6><span>{edit_task_id}</span>
                                    <div className="form-group row align-items-center">
                                        <label className="col-3">Name</label>
                                        <input value={edit_name} onChange={e => handleTaskEditFormInputChange(e)} className="form-control col" type="text" placeholder="Task name" name="edit-task-name" />
                                    </div>
                                    <div className="form-group row">
                                        <label className="col-3">Description</label>
                                        <textarea value={edit_descr} onChange={e => handleTaskEditFormInputChange(e)} className="form-control col" rows="3" placeholder="Task description" name="edit-task-description"></textarea>
                                    </div>
                                    <div className="form-group row align-items-center">
                                        <label className="col-3">Due Date</label>
                                        <Flatpickr
                                            options={{
                                                minDate: "2021-01-01",
                                                altInput: true,
                                                altFormat: "F j, Y",
                                                dateFormat: "Y-m-d"
                                            }}
                                            value={edit_due_dt}
                                            onChange={date => {
                                                setEditDueDate(date[0]);
                                            }}
                                        />
                                    </div>
                                    <div className="form-group row">
                                        <label className="col-3" htmlFor="edit-task-category">Category</label>

                                        <select value={edit_category} onChange={e => handleTaskEditFormInputChange(e)} name="edit-task-category" className="form-select col" aria-label="Task Category">
                                            <option defaultValue="">Select a category</option>
                                            {task_categories.map((cat) => (
                                                <option key={cat} value={cat}> {cat}</option>
                                            ))
                                            }
                                        </select>
                                    </div>

                                    <div className="form-group row">
                                        <label className="col-3" htmlFor="task-team">Team</label>

                                        <select value={edit_team} onChange={e => handleTaskEditFormInputChange(e)} name="edit-task-team" className="form-select col" aria-label="Task Team">
                                            <option defaultValue="">Select a team</option>
                                            {teams.map((team) => (
                                                <option key={team.id} value={team.id}> {team.name}</option>
                                            ))
                                            }
                                        </select>
                                    </div>
                                    <div className="form-group row">
                                        <label className="col-3" htmlFor="task-seq">Sequence</label>
                                        <Button variant="primary" id="button-dep" onClick={e => handleEditSequenceDec(e)}>
                                            -
                                        </Button>
                                        <input value={edit_sequence} onChange={e => handleTaskEditFormInputChange(e)} className="form-control col" type="text" placeholder="Task sequence" name="edit-task-seq" disabled={true} />
                                        <Button variant="primary" id="button-inc" onClick={e => handleEditSequenceInc(e)}>
                                            +
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={e => handleEditTaskClose(e)}>
                                Close
                            </Button>
                            <Button variant="primary" onClick={e => handleEditTask(e)}>
                                Save
                            </Button>
                        </Modal.Footer>
                    </Modal>

                    <Modal show={toggleAddTask} animation={false}>
                        <Modal.Header>
                            <Modal.Title>Add Task to Project</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div className="tab-content">
                                <FormErrors formErrors={AddTaskformErrors} />
                                <div className="tab-pane fade active show" id="task-add-details" role="tabpanel">
                                    <h6>Task Details</h6>
                                    <div className="form-group row align-items-center">
                                        <label className="col-3">Name</label>
                                        <input value={name} onChange={e => handleTaskFormInputChange(e)} className="form-control col" type="text" placeholder="Task name" name="task-name" />
                                    </div>
                                    <div className="form-group row">
                                        <label className="col-3">Description</label>
                                        <textarea value={descr} onChange={e => handleTaskFormInputChange(e)} className="form-control col" rows="3" placeholder="Task description" name="task-description"></textarea>
                                    </div>
                                    <div className="form-group row align-items-center">
                                        <label className="col-3">Due Date</label>
                                        <Flatpickr
                                            options={{
                                                minDate: "2021-01-01",
                                                altInput: true,
                                                altFormat: "F j, Y",
                                                dateFormat: "Y-m-d"
                                            }}
                                            value={due_dt}
                                            onChange={date => {
                                                setDueDate(date[0]);
                                            }}
                                        />
                                    </div>
                                    <div className="form-group row">
                                        <label className="col-3" htmlFor="task-category">Category</label>

                                        <select value={category} onChange={e => handleTaskFormInputChange(e)} name="task-category" className="form-select col" aria-label="Task Category">
                                            <option defaultValue="">Select a category</option>
                                            {task_categories.map((cat) => (
                                                <option key={cat} value={cat}> {cat}</option>
                                            ))
                                            }
                                        </select>
                                    </div>

                                    <div className="form-group row">
                                        <label className="col-3" htmlFor="task-team">Team</label>

                                        <select value={team} onChange={e => handleTaskFormInputChange(e)} name="task-team" className="form-select col" aria-label="Task Team">
                                            <option defaultValue="">Select a team</option>
                                            {teams.map((team) => (
                                                <option key={team.id} value={team.id}> {team.name}</option>
                                            ))
                                            }
                                        </select>
                                    </div>
                                    <div className="form-group row">
                                        <label className="col-3" htmlFor="task-seq">Sequence</label>
                                        <Button variant="primary" id="button-dep" onClick={e => handleSequenceDec(e)}>
                                            -
                                        </Button>
                                        <input value={sequence} onChange={e => handleTaskFormInputChange(e)} className="form-control col" type="text" placeholder="Task sequence" name="task-seq" disabled={true} />
                                        <Button variant="primary" id="button-inc" onClick={e => handleSequenceInc(e)}>
                                            +
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </Modal.Body>

                        <Modal.Footer>
                            <Button variant="secondary" onClick={e => handleShowTaskClose(e)}>
                                Close
                            </Button>
                            <Button variant="primary" onClick={e => handleAddTask(e)}>
                                Add Task
                            </Button>
                        </Modal.Footer>
                    </Modal>

                    <Modal show={toggleDeleteTask} animation={false}>
                        <Modal.Header>
                            <Modal.Title>Delete Task?</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div className="tab-content">
                                <div className="tab-pane fade active show" id="task-add-details" role="tabpanel">
                                    <h6>Confirm Delete Task?</h6>
                                    {delete_task_id}
                                </div>
                            </div>
                        </Modal.Body>

                        <Modal.Footer>
                            <Button variant="secondary" onClick={e => handleDeleteTaskCancel(e)}>
                                Close
                            </Button>
                            <Button variant="primary" onClick={e => TaskDelete(e)}>
                                Delete Task
                            </Button>
                        </Modal.Footer>
                    </Modal>
                </div>
            }
        </div>
    )
}

export default Project;