import { React, useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Modal, Button } from "react-bootstrap";
import { v4 } from 'uuid';
import { getTaskSub, getTeam, addTaskNote, addChecklistItem, editTaskChecklist, editTaskNote } from './firebase';
import Avatars from './Avatars';
import ProgressBar from './ProgressBar';
import { Link } from "react-router-dom";
import './Task.css';
import ActivityCard from './ActivityCard';


function Task(props) {

    const id = useParams();
    const [currentView, setCurrentView] = useState("tasks");
    const [toggleAddNote, setToggleAddNote] = useState(false);
    const [toggleEditNote, setToggleEditNote] = useState(false);
    //const [toggleDeleteNote, setToggleDeleteNote] = useState(false);
    const [toggleNewChecklist, setToggleNewChecklist] = useState(false);
    const [task, setTask] = useState();
    const [team, setTeam] = useState({});
    const [noteText, setNoteText] = useState("");
    const [noteTitle, setNoteTitle] = useState("");
    const [checklist, setChecklist] = useState("");
    const [editNote, setEditNote] = useState({});

    const getTaskData = (doc) => {
        console.log("Current data: ", doc.data());
        setTask(doc.data());

        console.log(doc.data().team);

        let team_id = doc.data().team;

        getTeam(team_id).then((r) => {
            console.log(r);
            setTeam(r);
        });
    }

    useEffect(() => {
        //console.log('useEffect called... ');
        let t = getTaskSub(id.id, id.task, getTaskData);

        return () => {
            //console.log('unmounting the project component.');
            t();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleNoteFormInputChange = (e) => {
        const name = e.target.name;
        const value = e.target.value;

        if (name === "note-name") {
            setNoteTitle(value);
        }

        if (name === "note-description") {
            setNoteText(value);
        }

    }

    const handleChecklistFormInputChange = (e) => {
        const name = e.target.name;
        const value = e.target.value;

        if (name === "checklist") {
            setChecklist(value);
        }

    }

    const handleToggleEditNote = (e, note) => {
        e.preventDefault();
        setNoteTitle(note.title);
        setNoteText(note.text);
        setEditNote(note);
        setToggleEditNote(true);
    }

    const handleNoteEdit = (e) => {
        e.preventDefault();
        //console.log();
        //do the edit in the database here...
        let index = task.notes.findIndex(element => element.id === editNote.id);
        
        editTaskNote(id.id, id.task, noteTitle, noteText, index).then(() => {
            setToggleEditNote(false);
            setNoteText("");
            setNoteTitle("");
        })
        
    }

    const handleNoteEditClose = (e) => {
        e.preventDefault();
        setToggleEditNote(false);
        setNoteText("");
        setNoteTitle("");
    }

    const handleChangeCurrentView = (e, view) => {
        e.preventDefault();
        setCurrentView(view);
    }

    const handleShowNoteClose = (e) => {
        e.preventDefault();
        setToggleAddNote(false);

    }

    const handleAddNote = (e) => {
        e.preventDefault();

        const guid = v4();

        addTaskNote(id.id, id.task, guid, noteTitle, noteText).then(() => {
            setToggleAddNote(false);
            setNoteText("");
            setNoteTitle("");
        });

    }

    const handleShowChecklistClose = (e) => {
        e.preventDefault();
        setToggleNewChecklist(false);


    }

    const handleChecklistChanged = (e, item) => {
        e.preventDefault();
        console.log(e);
        console.log(item);

        let index = task.checklist.findIndex(element => element.id === item.id);

        editTaskChecklist(id.id, id.task, index);

    }

    const handleAddChecklistItem = (e) => {
        e.preventDefault();

        const guid = v4();
        addChecklistItem(id.id, id.task, guid, checklist).then(() => {
            setChecklist("");
            setToggleNewChecklist(false);
        });

    }

    const handleToggleNewNote = (e) => {
        e.preventDefault();

        setToggleAddNote(true);
    }

    const handleToggleNewChecklist = (e) => {
        e.preventDefault();

        setToggleNewChecklist(true);
    }

    const differenceInDays = (date1, date2) => {
        var Difference_In_Time = date2.getTime() - date1.getTime();
        var Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);
        return Difference_In_Days;
    }

    const calc_percent_complete = () => {
        if (task.checklist.length === 0) {
            return 0;
        }
        else
            return (count_completed_checklists() / task.checklist.length) * 100;
        //return 85;
    }

    const progress_bar_style = () => {
        let percent_complete = calc_percent_complete();

        if (percent_complete < 50) {
            return "progress-bar bg-danger";
        }

        if (percent_complete < 75) {
            return "progress-bar bg-warning";
        }

        if (percent_complete > 75) {
            return "progress-bar bg-success";
        }
    }

    const count_completed_checklists = () => {
        let completed_tasks = 0;
        task.checklist.forEach((i) => {
            if (i.complete === true)
                completed_tasks++;
        });
        return completed_tasks;
    }

    return (
        <div className="main-container">
            {task &&
                <div className="container">
                    <div className="navbar breadcrumb-bar">
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item">Project</li>
                                <li className="breadcrumb-item"><Link to={"/project/" + task.project_id}>{task.project_id}</Link></li>
                                <li className="breadcrumb-item">Task</li>
                                <li className="breadcrumb-item active" aria-current="page">{task.id}</li>
                            </ol>
                        </nav>
                    </div>
                    <div className="row justify-content-center">
                        <div className="col-lg-11 col-xl-10">
                            <div className="page-header">
                                <h1>{task.name}</h1>
                                <p className="lead">{task.descr}</p>
                                <div className="d-flex align-items-center">
                                    <Avatars users={team.members} master_members_list={props.master_members_list} />
                                </div>
                                <div>
                                    <ProgressBar width={calc_percent_complete() + "%"} style_class={progress_bar_style()} />
                                    <div className="d-flex justify-content-between text-small">
                                        <div className="d-flex align-items-center">
                                            <i className="material-icons">playlist_add_check</i>
                                            <span>{count_completed_checklists()}/{task.checklist.length}</span>
                                        </div>
                                        <span>Due in {Math.floor(differenceInDays(new Date(), new Date(task.due_dt)))} Day(s)</span>
                                        <span>{task.due_dt.toDate().toLocaleDateString('en-us')}</span>
                                    </div>
                                </div>
                            </div>
                            <ul className="nav nav-tabs nav-fill" role="tablist">
                                <li className="nav-item">
                                    <a className={currentView === 'tasks' ? "nav-link active" : "nav-link"} data-toggle="tab" onClick={e => handleChangeCurrentView(e, 'tasks')} href="#task" role="tab" aria-controls="task" aria-selected={currentView === 'tasks' ? 'true' : 'false'}>Task</a>
                                </li>
                                <li className="nav-item">
                                    <a className={currentView === 'files' ? "nav-link active" : "nav-link"} data-toggle="tab" onClick={e => handleChangeCurrentView(e, 'files')} href="#files" role="tab" aria-controls="files" aria-selected={currentView === 'files' ? 'true' : 'false'}>Files</a>
                                </li>
                                <li className="nav-item">
                                    <a className={currentView === 'activity' ? "nav-link active" : "nav-link"} data-toggle="tab" onClick={e => handleChangeCurrentView(e, 'activity')} href="#activity" role="tab" aria-controls="activity" aria-selected={currentView === 'activity' ? 'true' : 'false'}>Activity</a>
                                </li>
                            </ul>
                            <div className="tab-content">
                                {currentView === 'tasks' &&
                                    <div className="tab-pane fade show active" id="tasks" role="tabpanel" data-filter-list="card-list-body">
                                        <div className="content-list" data-filter-list="checklist">
                                            <div className="row content-list-head">
                                                <div className="col-auto">
                                                    <h3>Checklist</h3>
                                                    <button onClick={e => handleToggleNewChecklist(e)} className="btn btn-round" data-toggle="tooltip" data-title="New item">
                                                        <i className="material-icons">add</i>
                                                    </button>
                                                </div>
                                            </div>
                                            <div className="content-list-body">
                                                <form className="checklist filter-list-1641077755282" tabIndex={0}>
                                                    {
                                                        task.checklist.map((item) => (
                                                            <div className="row" tabIndex={0}>
                                                                <div className="form-group col">
                                                                    <span className="checklist-reorder">
                                                                        <i className="material-icons">reorder</i>
                                                                    </span>
                                                                    <div onClick={e => handleChecklistChanged(e, item)} name={item.id} key={item.id} className="custom-control custom-checkbox col">
                                                                        <input onChange={e => handleChecklistChanged(e, item)} key={item.id} type="checkbox" className="custom-control-input" id={item.id} checked={item.complete} />
                                                                        <label className="custom-control-label" htmlFor="checklist-item-1"></label>
                                                                        <div style={{width: '100%'}}>
                                                                            <input onChange={e => console.log('changed')} key={item.item} style={{width: '100%'}} type="text" placeholder="Checklist item" value={item.item} data-filter-by="value" className="filter-by-value" />
                                                                            <div className="checklist-strikethrough"></div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        ))
                                                    }
                                                </form>
                                                <div className="drop-to-delete" tabIndex={0}>
                                                    <div className="drag-to-delete-title">
                                                        <i className="material-icons">delete</i>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="content-list" data-filter-list="content-list-body">
                                            <div className="row content-list-head">
                                                <div className="col-auto">
                                                    <h3>Notes</h3>
                                                    <button onClick={e => handleToggleNewNote(e)} className="btn btn-round" data-toggle="modal" data-target="#note-add-modal">
                                                        <i className="material-icons">add</i>
                                                    </button>
                                                </div>
                                            </div>
                                            <div className="content-list-body filter-list-1641077755280">
                                                {
                                                    task.notes.map((note) => (
                                                        <div key={note.id} className="card card-note">
                                                            <div className="card-header">
                                                                <div className="media align-items-center">
                                                                    <Avatars users={[note.user]} master_members_list={props.master_members_list} />
                                                                    <div className="media-body">
                                                                        <h6 className="mb-0 H6-filter-by-text" data-filter-by="text">{note.title}</h6>
                                                                    </div>
                                                                </div>
                                                                <div className="d-flex align-items-center">
                                                                    <span data-filter-by="text" className="SPAN-filter-by-text">{note.create_dt.toDate().toLocaleDateString('en-us')}</span>
                                                                    <div className="dropdown card-options show">
                                                                        <button onClick={e => handleToggleEditNote(e, note)} className="btn-options" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded={true}>
                                                                            <i className="material-icons">edit</i>
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="card-body DIV-filter-by-text" data-filter-by="text">
                                                                <p>{note.text}</p>
                                                            </div>
                                                        </div>
                                                    ))
                                                }
                                            </div>
                                        </div>
                                    </div>
                                }
                                {currentView === 'files' &&
                                    <div className="tab-pane fade show active" id="files" role="tabpanel" data-filter-list="card-list-body">
                                        <div className="row content-list-head">
                                            <div className="col-auto">
                                                <h3>Files</h3>
                                            </div>
                                        </div>
                                        <div className="content-list-body">
                                            Under Construction...
                                        </div>
                                    </div>
                                }
                                {currentView === 'activity' &&
                                    <div className="tab-pane fade show active" id="activity" role="tabpanel" data-filter-list="card-list-body">
                                        <div className="row content-list-head">
                                            <div className="col-auto">
                                                <h3>Activity</h3>
                                            </div>
                                        </div>
                                        <div className="content-list-body">
                                            <ol className="list-group list-group-activity filter-list-1641391464192">
                                                {
                                                    task.activity.map((act) => (
                                                        <ActivityCard master_members_list={props.master_members_list} activity={act} />
                                                    ))
                                                }
                                            </ol>
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                    <Modal show={toggleNewChecklist} animation={false}>
                        <Modal.Header>
                            <Modal.Title>Add New Checklist</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div className="form-group row align-items-center">
                                <label className="col-3">Checklist Item</label>
                                <input value={checklist} onChange={e => handleChecklistFormInputChange(e)} className="form-control col" type="text" placeholder="Checklist Item" name="checklist" />
                            </div>
                        </Modal.Body>

                        <Modal.Footer>
                            <Button variant="secondary" onClick={e => handleShowChecklistClose(e)}>
                                Close
                            </Button>
                            <Button variant="primary" onClick={e => handleAddChecklistItem(e)}>
                                Add Checklist
                            </Button>
                        </Modal.Footer>
                    </Modal>

                    <Modal show={toggleAddNote} animation={false}>
                        <Modal.Header>
                            <Modal.Title>Add New Note</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div className="form-group row align-items-center">
                                <label className="col-3">Title</label>
                                <input value={noteTitle} onChange={e => handleNoteFormInputChange(e)} className="form-control col" type="text" placeholder="Note title" name="note-name" />
                            </div>
                            <div className="form-group row">
                                <label className="col-3">Text</label>
                                <textarea value={noteText} onChange={e => handleNoteFormInputChange(e)} className="form-control col" rows="6" placeholder="Body text for note" name="note-description"></textarea>
                            </div>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={e => handleShowNoteClose(e)}>
                                Close
                            </Button>
                            <Button variant="primary" onClick={e => handleAddNote(e)}>
                                Create Note
                            </Button>
                        </Modal.Footer>
                    </Modal>
                    <Modal show={toggleEditNote} animation={false}>
                        <Modal.Header>
                            <Modal.Title>Edit Note</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div className="form-group row align-items-center">
                                <label className="col-3">Title</label>
                                <input value={noteTitle} onChange={e => handleNoteFormInputChange(e)} className="form-control col" type="text" placeholder="Note title" name="note-name" />
                            </div>
                            <div className="form-group row">
                                <label className="col-3">Text</label>
                                <textarea value={noteText} onChange={e => handleNoteFormInputChange(e)} className="form-control col" rows="6" placeholder="Body text for note" name="note-description"></textarea>
                            </div>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={e => handleNoteEditClose(e)}>
                                Close
                            </Button>
                            <Button variant="primary" onClick={e => handleNoteEdit(e)}>
                                Edit Note
                            </Button>
                        </Modal.Footer>
                    </Modal>
                </div>
            }
        </div>
    )
}

export default Task;