import React, { useEffect, useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import { auth } from "./firebase";
import { setPersistence, browserLocalPersistence, browserSessionPersistence, sendPasswordResetEmail, signInWithEmailAndPassword } from "firebase/auth";
import { useAuthState } from "react-firebase-hooks/auth";
import "./Login.css";
import logo from "./assets/shawgo_logo_small.png";
import Socials from "./Socials";
import { Alert } from "react-bootstrap";

function Login(props) {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [forgot, setForgot] = useState(false);
    const [remember, setRemember] = useState(false);
    const [Error, setError] = useState(null);
    const [user] = useAuthState(auth);
    const navigate = useNavigate();
    useEffect(() => {
        if (user) navigate("/dashboard");

        if (Error) {

        }
        
    }, [Error, navigate, user]);

    const toggleRemember = (checked) => setRemember(checked);

    const handleLogin = async (e, email, password, remember) => {
        e.preventDefault();
        (remember ? setPersistence(auth, browserLocalPersistence) : setPersistence(auth, browserSessionPersistence))
            .then(async () => {
                const user = await signInWithEmailAndPassword(auth, email, password);
                await props.onLogin(user.user.uid);
                
                navigate("/dashboard");
            })
            .catch(e => {
                setError(e.message);
            });
    };

    const handleSendPasswordResetEmail = (event) => {
        event.preventDefault();
        console.log(email);
        sendPasswordResetEmail(auth, email)
            .then(() => {
                setForgot(false);
            })
            .catch((error) => {
                console.log(error);
                setError(error.message);
            });
    };

    return (
        <section className="main">
            <div className="layer">
                <div className="bottom-grid">
                    <div className="logo">
                        <img src={logo} alt="shawgo logo" />
                    </div>
                    <div className="links">
                        <Socials />
                    </div>
                </div>
                {!forgot &&
                    <div className="content-grid">
                        <div className="text-center icon">
                            <div className="logo"><h1>Client Portal</h1></div>
                        </div>
                        <div className="content-bottom">
                            {Error &&
                                <Alert variant="danger">
                                    {Error}
                                </Alert>
                            }
                            <form action="#" method="post">
                                <div className="field-group">
                                    <span className="fa fa-envelope" aria-hidden="true"></span>
                                    <div className="content-input-field">
                                        <input name="text1" id="text1" type="text" placeholder="Email Address" value={email}
                                            onChange={(e) => setEmail(e.target.value)} required />
                                    </div>
                                </div>
                                <div className="field-group">
                                    <span className="fa fa-lock" aria-hidden="true"></span>
                                    <div className="content-input-field">
                                        <input name="password" id="myInput" type="Password" placeholder="Password" value={password}
                                            onChange={(e) => setPassword(e.target.value)} />
                                    </div>
                                </div>
                                <div className="content-input-field">
                                    <button onClick={(event) => handleLogin(event, email, password, remember)} type="submit" className="btn">Get Started</button>
                                </div>
                                <ul className="list-login">
                                    <li className="switch-slide">
                                        <label className="switch">
                                            <input type="checkbox" onChange={(e) => toggleRemember(e.target.checked)} />
                                            <span className="slider round"></span>
                                            keep Logged in
                                        </label>
                                    </li>
                                    <li id="forgot">
                                        <Link to="/" onClick={(event) => { setForgot(true); setError(null); return event.preventDefault; }}>forgot password?</Link>
                                    </li>
                                    <li className="clearfix"></li>
                                </ul>
                                <ul className="list-login-bottom">
                                    <li className="clearfix"></li>
                                </ul>
                            </form>
                        </div>
                    </div>}
                {forgot &&
                    <div className="content-grid">
                        <div className="text-center icon">
                            <div className="logo"><h1>Forgot your password?</h1></div>
                            <div className="forgotInstructions">Tell us your email address and we can send you instructions to reset your password.</div>
                        </div>
                        <div className="content-bottom">
                            {Error &&
                                <Alert variant="danger">
                                    {Error}
                                </Alert>
                            }
                            <form action="#" method="post">
                                <div className="field-group">
                                    <span className="fa fa-envelope" aria-hidden="true"></span>
                                    <div className="content-input-field">
                                        <input name="text1" id="text1" type="text" placeholder="Email Address" value={email}
                                            onChange={(e) => setEmail(e.target.value)} required />
                                    </div>
                                </div>
                                <div className="content-input-field">
                                    <button onClick={(event) => handleSendPasswordResetEmail(event)} type="submit" className="btn">Email some help</button>
                                </div>
                                <ul className="list-login">
                                    <li>
                                        <p className="whiteText">
                                            Can't remember your account? &nbsp;
                                            <a className="accountHelp" href="mailto:coderken9@gmail.com?subject=I can't recover my account's password, help!">Let us know!</a>
                                        </p>
                                    </li>
                                    <li className="clearfix"></li>
                                </ul>
                                <ul className="list-login-bottom">
                                    <li className="clearfix"></li>
                                </ul>
                            </form>
                        </div>
                    </div>}
                <div className="bottom-grid1">
                    <div className="links">
                        <ul className="links-unordered-list">
                            <li className="">
                                <Link to="/privacy" onClick={(event) => { navigate("/privacy"); return event.preventDefault; }}> Privacy Policy </Link>
                            </li>
                            <li className="">
                                <Link to="/terms" onClick={(event) => { navigate("/terms"); return event.preventDefault; }}> Terms of Use </Link>
                            </li>
                        </ul>
                    </div>
                    <div className="copyright">
                        <p>© {new Date().getFullYear()} Shawgo Real Estate LLC. All rights reserved.</p>
                    </div>
                </div>
            </div>
        </section>
    );
}
export default Login;