// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { collection, query, where, getDocs, getFirestore, setDoc, getDoc, doc, onSnapshot, deleteDoc, runTransaction, arrayUnion } from "firebase/firestore";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { getAuth, onAuthStateChanged, updatePassword, sendPasswordResetEmail } from "firebase/auth";
import { useAuthState } from "react-firebase-hooks/auth";
import axios from 'axios';
//import { getFirestore } from 'firebase/firestore/lite'
//import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyBmoN_m0UTgBA2mtHAFFIiSGEhaywwgct0",
    authDomain: "customer-portal-shawgo.firebaseapp.com",
    projectId: "customer-portal-shawgo",
    storageBucket: "customer-portal-shawgo.appspot.com",
    messagingSenderId: "500762414044",
    appId: "1:500762414044:web:e7df8d6ff73deb089d1d19",
    measurementId: "G-MH763Z7BY6"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);
const teamsRef = collection(db, "teams");
const userRef = collection(db, "users");
const projRef = collection(db, "projects");
const default_avatar_url = "https://firebasestorage.googleapis.com/v0/b/customer-portal-shawgo.appspot.com/o/avatars%2Fdefault_user.jpg?alt=media&token=6ed7a745-44a1-4aa5-bafe-638339cc9da2";

//const analytics = getAnalytics(app);
let uid;

onAuthStateChanged(auth, (user) => {
    if (user) {
        uid = user.uid;

    } else {
        // User is signed out

    }
});

const getUserToken = async () => {
    let user_token = "";
    user_token = await auth.currentUser.getIdToken();

    return user_token;
}

const PasswordResetEmail = async (email) => {
    console.log(email);
    await sendPasswordResetEmail(auth, email)
        .then(() => {
            console.log('yah?');
        })
        .catch((e) => {
            console.log(e);
        });
}

const createNewUser = async (email, first_name, last_name, phone, title) => {
    const base_url = "https://us-central1-customer-portal-shawgo.cloudfunctions.net/createNewUser";
    let user_token = "";
    user_token = await auth.currentUser.getIdToken();

    console.log(user_token);

    await axios.get(base_url, {
        params: {
            fname: first_name,
            lname: last_name,
            title: title,
            phone: phone,
            email: email,
        },
        headers: { 'Authorization': "Bearer " + user_token }
    })
        .then(async (response) => {
            console.log(response);
            await setDoc(doc(db, "users", response.data), {
                first_name: first_name,
                last_name: last_name,
                email: email,
                phone: phone,
                uid: response.data,
                avatar_url: default_avatar_url,
                title: title,
                role: "",
                current_team: "",
            });
            await PasswordResetEmail(email);
        })
        .catch(function (error) {
            //console.log(error);
        })
        .then(function () {
            // always executed
        });
    //send password reset email...
}

const getSetRoleClaim = async (uid, role) => {
    const base_url = "https://us-central1-customer-portal-shawgo.cloudfunctions.net/addRoleClaim";
    let user_token = "";
    user_token = await auth.currentUser.getIdToken();

    //console.log(user_token);

    await axios.get(base_url, {
        params: {
            uid: uid,
            role: role
        },
        headers: { 'Authorization': "Bearer " + user_token }
    })
        .then(function (response) {
            //console.log(response);
        })
        .catch(function (error) {
            //console.log(error);
        })
        .then(function () {
            // always executed
        });
}

const getSetUserDisabled = async (uid, mode) => {
    const base_url = " https://us-central1-customer-portal-shawgo.cloudfunctions.net/enableDisableUser";
    let user_token = "";
    user_token = await auth.currentUser.getIdToken();

    //console.log(user_token);

    await axios.get(base_url, {
        params: {
            uid: uid,
            mode: mode
        },
        headers: { 'Authorization': "Bearer " + user_token }
    })
        .then(function (response) {
            //console.log(response);
        })
        .catch(function (error) {
            //console.log(error);
        })
        .then(function () {
            // always executed
        });
}

const getProjectTaskSub = (id, f) => {

    const q = query(collection(db, "projects/" + id.id + "/tasks"));
    const unsubscribe = onSnapshot(q, f);

    return unsubscribe;
}

const getTemplates = async () => {

    const docRef = doc(db, "templates", "yDM7P1kah5j4yEI6VEyk");
    const docSnap = await getDoc(docRef);
    let ret;

    if (docSnap.exists()) {
        console.log("Document data:", docSnap.data());
        ret = docSnap.data();
    } else {
        // doc.data() will be undefined in this case
        console.log("No such document!");
        ret = 0;
    }
    return ret;

}

const getProject = (id, f) => {
    //console.log(id);
    const unsubscribe = onSnapshot(doc(db, "projects", id.id), f);

    return unsubscribe;
}

const getTaskSub = (proj_id, task_id, f) => {
    console.log(task_id);
    console.log(proj_id);
    const unsub = onSnapshot(doc(db, "projects/" + proj_id + "/tasks", task_id), f);

    return unsub;
}

const getMyProjects = async () => {
    let projects = [];
    const user = await getUserProfile(uid);
    const q_agent = query(projRef, where('agent_id', '==', uid));
    const q_cust = query(projRef, where('customer_id', '==', uid));
    const q_sagent = query(projRef, where('seller_agent_id', '==', uid));
    const q_team = query(projRef, where("teams", "array-contains", user.current_team));
    const querySnapshotAgent = await getDocs(q_agent);
    const querySnapshotSagent = await getDocs(q_sagent);
    const querySnapshotCustomer = await getDocs(q_cust);
    const querySnapshotTeam = await getDocs(q_team);
    querySnapshotAgent.forEach((doc) => {
        console.log(doc.id, " => ", doc.data());
        projects.push(doc.data());
    });
    querySnapshotCustomer.forEach((doc) => {
        console.log(doc.id, " => ", doc.data());
        projects.push(doc.data());
    });
    querySnapshotSagent.forEach((doc) => {
        console.log(doc.id, " => ", doc.data());
        projects.push(doc.data());
    });
    querySnapshotTeam.forEach((doc) => {
        console.log(doc.id, " => ", doc.data());
        projects.push(doc.data());
    });

    return projects.filter(
        (thing, i, arr) => arr.findIndex(t => t.id === thing.id) === i);
}

const getTeamProjects = async (id) => {
    let projects = [];

    const q = query(projRef, where("teams", "array-contains", id));

    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
        console.log(doc.id, " => ", doc.data());
        projects.push(doc.data());
    });

    return projects;
}

const getProjectTasks = async (id) => {
    let tasks = [];
    console.log(id);
    const tasksRef = collection(db, "projects/" + id + "/tasks");
    const q = query(tasksRef);

    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
        // doc.data() is never undefined for query doc snapshots
        console.log(doc.id, " => ", doc.data());
        tasks.push(doc.data());
    });
    return tasks;
}

const GetAllTeams = async () => {
    let ret = [];
    const q = query(teamsRef);
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
        // doc.data() is never undefined for query doc snapshots
        //console.log(doc.id, " => ", doc.data());
        ret.push(doc.data());
    });
    return ret;
}

const getTeams = async () => {
    let ret = [];
    //const q = query(teamsRef, where("name", "==", "Shawgo Real Estate"));
    if (typeof uid !== 'undefined') {
        const q = query(teamsRef, where('members', 'array-contains-any', [uid]));
        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => {
            // doc.data() is never undefined for query doc snapshots
            //console.log(doc.id, " => ", doc.data());
            ret.push(doc.data());
        });
    };
    return ret;
}

const getTeam = async (id) => {
    const docRef = doc(db, "teams", id);
    const docSnap = await getDoc(docRef);
    let ret;

    if (docSnap.exists()) {
        console.log("Document data:", docSnap.data());
        ret = docSnap.data();
    } else {
        // doc.data() will be undefined in this case
        console.log("No such document!");
        ret = 0;
    }
    return ret;
}

const getAgents = async () => {
    let ret = [];
    const q = query(userRef, where('agent', '==', true));
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
        // doc.data() is never undefined for query doc snapshots
        console.log(doc.id, " => ", doc.data());
        ret.push(doc.data());
    });
    return ret;
}

const getCustomers = async () => {
    let ret = [];
    const q = query(userRef, where('role', '==', 'client'));
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
        // doc.data() is never undefined for query doc snapshots
        console.log(doc.id, " => ", doc.data());
        ret.push(doc.data());
    });
    return ret;
}

const getUsers = async () => {
    let ret = [];
    const q = query(userRef);
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
        // doc.data() is never undefined for query doc snapshots
        console.log(doc.id, " => ", doc.data());
        ret.push(doc.data());
    });
    return ret;
}

const getUserProfile = async (u) => {
    //console.log(u);
    let ret;
    const q = query(userRef, where('uid', '==', u));
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
        // doc.data() is never undefined for query doc snapshots
        console.log(doc.id, " => ", doc.data());
        ret = doc.data();
    });
    return ret;
}

const logout = () => {
    return auth.signOut();
};

const CurrentUser = () => {
    const [user] = useAuthState(auth);
    return user;
}

const addNewTeam = async (team) => {
    //console.log(team);
    await setDoc(doc(db, "teams", team.id), team);
}

const addNewProject = async (project) => {
    project.activity.push({
        user: uid,
        descr: "Project: " + project.name + " (" + project.id + ") was created.",
        date: new Date(),
        type: "Add"
    });
    await setDoc(doc(db, "projects", project.id), project);
}

const addNewProjectTask = async (proj_id, id, name, descr, team_id, cat, seq, due_dt) => {
    const projRef = doc(db, "projects/" + proj_id);
    await setDoc(doc(db, "projects/" + proj_id + "/tasks", id), {
        id: id,
        project_id: proj_id,
        name: name,
        descr: descr,
        team: team_id,
        category: cat,
        seq: seq,
        due_dt: due_dt,
        activity: [],
        notes: [],
        files: [],
        checklist: []
    });

    try {
        await runTransaction(db, async (transaction) => {
            const projdoc_snap = await transaction.get(projRef);

            if (!projdoc_snap.exists()) {
                console.log('no doc exits...');
            }

            transaction.update(projRef, {
                activity: arrayUnion({
                    user: uid,
                    descr: "Task: " + name + " (" + id + ") was added to the project.",
                    date: new Date(),
                    type: "Add"
                })
            });
        });
        console.log("Transaction successfully committed!");
    } catch (e) {
        console.log("Transaction failed: ", e);
    }
}

const updateProfile = async (fname, lname, email_addr, phone_num, avatar, title, cur_team) => {
    let ret = "";
    await setDoc(doc(db, "users", uid), {
        first_name: fname,
        last_name: lname,
        email: email_addr,
        phone: phone_num,
        uid: uid,
        avatar_url: avatar,
        title: title,
        current_team: cur_team
    }).then(() => {
        ret = "success";
    })
        .catch(error => {
            console.error('error function called: ' + error.message);
            ret = error.message;
        })
    return ret;
}

const uploadAvatar = async (file) => {
    let ret_url = "";
    if (typeof uid !== 'undefined') {
        const storage = getStorage(app);
        const avatarRef = ref(storage, 'avatars/' + uid + ' - ' + file.name);

        let snapshot = await uploadBytes(avatarRef, file);

        let downloadURL = await getDownloadURL(snapshot.ref);
        console.log('File available at', downloadURL);
        ret_url = downloadURL;
        console.log(ret_url);
        return ret_url;
    }
}

const deleteTask = async (proj_id, task_id) => {
    const projRef = doc(db, "projects/" + proj_id);
    await deleteDoc(doc(db, "projects/" + proj_id + "/tasks", task_id));

    try {
        await runTransaction(db, async (transaction) => {
            const projdoc_snap = await transaction.get(projRef);

            if (!projdoc_snap.exists()) {
                console.log('no doc exits...');
            }

            transaction.update(projRef, {
                activity: arrayUnion({
                    user: uid,
                    descr: "Task: " + task_id + " was deleted from the project.",
                    date: new Date(),
                    type: "Delete"
                })
            });
        });
        console.log("Transaction successfully committed!");
    } catch (e) {
        console.log("Transaction failed: ", e);
    }


}

const editTaskChecklist = async (proj_id, task_id, index) => {
    const taskRef = doc(db, "projects/" + proj_id + "/tasks", task_id);
    const projRef = doc(db, "projects/" + proj_id);

    try {
        await runTransaction(db, async (transaction) => {
            const taskdoc_snap = await transaction.get(taskRef);
            const projdoc_snap = await transaction.get(projRef);
            if (!taskdoc_snap.exists()) {
                console.log('no doc exits...');
            }

            if (!projdoc_snap.exists()) {
                console.log('no doc exits...');
            }

            let temp_checklist = [...taskdoc_snap.data().checklist];

            temp_checklist[index].complete = !temp_checklist[index].complete;

            transaction.update(taskRef, {
                checklist: temp_checklist,
                activity: arrayUnion({
                    user: uid,
                    descr: temp_checklist[index].complete === true ? "Checklist: " + temp_checklist[index].item + " (" + temp_checklist[index].id + ") was Completed." : "Checklist: " + temp_checklist[index].item + " (" + temp_checklist[index].id + ") was Uncompleted.",
                    date: new Date(),
                    type: "Check"
                })
            });

            transaction.update(projRef, {
                activity: arrayUnion({
                    user: uid,
                    descr: temp_checklist[index].complete === true ? "Checklist: " + temp_checklist[index].item + " (" + temp_checklist[index].id + ") was Completed." : "Checklist: " + temp_checklist[index].item + " (" + temp_checklist[index].id + ") was Uncompleted.",
                    date: new Date(),
                    type: "Check"
                })
            });
        });
        console.log("Transaction successfully committed!");
    } catch (e) {
        console.log("Transaction failed: ", e);
    }
}

const editTask = async (proj_id, task_id, name, seq, due_dt, category, team, descr) => {
    const taskRef = doc(db, "projects/" + proj_id + "/tasks", task_id);
    const projRef = doc(db, "projects/" + proj_id);

    try {
        await runTransaction(db, async (transaction) => {
            const taskdoc_snap = await transaction.get(taskRef);
            const projdoc_snap = await transaction.get(projRef);
            if (!taskdoc_snap.exists()) {
                console.log('no doc exits...');
            }

            if (!projdoc_snap.exists()) {
                console.log('no doc exits...');
            }

            transaction.update(taskRef, {
                name: name,
                seq: seq,
                team: team,
                due_dt: due_dt,
                category: category,
                descr: descr,
                activity: arrayUnion({
                    user: uid,
                    descr: "Task: " + name + " (" + task_id + ") was edited.",
                    date: new Date(),
                    type: "Edit"
                })
            });

            transaction.update(projRef, {
                activity: arrayUnion({
                    user: uid,
                    descr: "Task: " + name + " (" + task_id + ") was edited.",
                    date: new Date(),
                    type: "Edit"
                })
            });
        });
        console.log("Transaction successfully committed!");
    } catch (e) {
        console.log("Transaction failed: ", e);
    }
}

const addTaskNote = async (proj_id, task_id, guid, title, text) => {
    const taskRef = doc(db, "projects/" + proj_id + "/tasks", task_id);
    const projRef = doc(db, "projects/" + proj_id);

    try {
        await runTransaction(db, async (transaction) => {
            const taskdoc_snap = await transaction.get(taskRef);
            const projdoc_snap = await transaction.get(projRef);
            if (!taskdoc_snap.exists()) {
                console.log('no doc exits...');
            }

            if (!projdoc_snap.exists()) {
                console.log('no doc exits...');
            }

            transaction.update(taskRef, {
                notes: arrayUnion({
                    "id": guid,
                    "title": title,
                    "text": text,
                    "user": uid,
                    "create_dt": new Date()
                }),
                activity: arrayUnion({
                    user: uid,
                    descr: "Note: " + title + " added to task_id: " + task_id,
                    date: new Date(),
                    type: "Add"
                })
            });

            transaction.update(projRef, {
                activity: arrayUnion({
                    user: uid,
                    descr: "Note: " + title + " added to task_id: " + task_id,
                    date: new Date(),
                    type: "Add"
                })
            });
        });
        console.log("Transaction successfully committed!");
    } catch (e) {
        console.log("Transaction failed: ", e);
    }
}

const editTaskNote = async (proj_id, task_id, title, text, index) => {
    const taskRef = doc(db, "projects/" + proj_id + "/tasks", task_id);
    const projRef = doc(db, "projects/" + proj_id);

    try {
        await runTransaction(db, async (transaction) => {
            const taskdoc_snap = await transaction.get(taskRef);
            const projdoc_snap = await transaction.get(projRef);
            if (!taskdoc_snap.exists()) {
                console.log('no doc exits...');
            }

            if (!projdoc_snap.exists()) {
                console.log('no doc exits...');
            }

            let temp_notes = [...taskdoc_snap.data().notes];

            temp_notes[index].title = title;
            temp_notes[index].text = text;

            transaction.update(taskRef, {
                notes: temp_notes,
                activity: arrayUnion({
                    user: uid,
                    descr: "Note: " + temp_notes[index].id + " changed.",
                    date: new Date(),
                    type: "Edit"
                })
            });

            transaction.update(projRef, {
                activity: arrayUnion({
                    user: uid,
                    descr: "Note: " + temp_notes[index].id + " changed.",
                    date: new Date(),
                    type: "Edit"
                })
            });
        });
        console.log("Transaction successfully committed!");
    } catch (e) {
        console.log("Transaction failed: ", e);
    }

}

const addChecklistItem = async (proj_id, task_id, guid, checklist_item_text) => {
    const taskRef = doc(db, "projects/" + proj_id + "/tasks", task_id);
    const projRef = doc(db, "projects/" + proj_id);

    try {
        await runTransaction(db, async (transaction) => {
            const taskdoc_snap = await transaction.get(taskRef);
            const projdoc_snap = await transaction.get(projRef);
            if (!taskdoc_snap.exists()) {
                console.log('no doc exits...');
            }
            if (!projdoc_snap.exists()) {
                console.log('no doc exits...');
            }

            transaction.update(taskRef, {
                checklist: arrayUnion({
                    "id": guid,
                    "item": checklist_item_text,
                    "user": uid,
                    "complete": false,
                    "create_dt": new Date()
                }),
                activity: arrayUnion({
                    user: uid,
                    descr: "Checklist Item: " + checklist_item_text + " added to task_id: " + task_id,
                    date: new Date(),
                    type: "Add"
                })
            });

            transaction.update(projRef, {
                activity: arrayUnion({
                    user: uid,
                    descr: "Checklist Item: " + checklist_item_text + " added to task_id: " + task_id,
                    date: new Date(),
                    type: "Add"
                })
            });
        });
        console.log("Transaction successfully committed!");
    } catch (e) {
        console.log("Transaction failed: ", e);
    }
}

const changePassword = async (newPassword) => {
    const user = auth.currentUser;
    let ret_val = "";
    await updatePassword(user, newPassword)
        .then(() => {
            // Update successful.
            //console.log('password update successful!');
            ret_val = "success";
        })
        .catch((error) => {
            // An error ocurred
            ret_val = error.message;
            console.log(ret_val);
        });

    return ret_val;
}

export {
    auth,
    logout,
    getTeams,
    CurrentUser,
    updateProfile,
    getUserProfile,
    uploadAvatar,
    changePassword,
    getUsers,
    addNewTeam,
    getTeam,
    getCustomers,
    getAgents,
    addNewProject,
    GetAllTeams,
    getMyProjects,
    getProjectTasks,
    getProject,
    getProjectTaskSub,
    addNewProjectTask,
    deleteTask,
    getTaskSub,
    addTaskNote,
    addChecklistItem,
    getTeamProjects,
    editTask,
    editTaskChecklist,
    editTaskNote,
    getTemplates,
    getSetRoleClaim,
    getSetUserDisabled,
    createNewUser,
    getUserToken
};