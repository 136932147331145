import "./Navbar.css";
import { React, useEffect, useState } from "react";
import logo from "../src/assets/shawgo_logo_small.png"
import { useNavigate, Link } from "react-router-dom";
import { logout } from "./firebase";

function Navbar(props) {
    const navigate = useNavigate();
    const [toggleDropdown, setToggleDropdown] = useState(false);

    useEffect(() => {
        //if (!user) navigate("/");
        console.log(props.profile);

    }, [props]);

    const Handlelogout = (e) => {
        e.preventDefault();
        logout()
            .then(() => {
                setToggleDropdown(false);
                props.onLogout();
            });
    }

    const handleNavigate = (e, r) => {
        e.preventDefault();
        setToggleDropdown(false);
        navigate(r);
    }

    const handleToggleDropdown = (e) => {
        e.preventDefault();
        setToggleDropdown(!toggleDropdown);
        //console.log('toggle clicked...' + toggleDropdown);
    }

    return (
        <div className="navbar navbar-expand-lg bg-shawgo-green navbar-dark sticky-top">

            <Link className="navbar-brand" to="/dashboard">
                <img className="responsive" alt="Shawgo Real Estate Logo" src={logo} />
            </Link>
            <div className="collapse navbar-collapse justify-content-between" id="navbar-collapse">
                <ul className="navbar-nav">
                    <li className="nav-item">
                        <Link className="navbar-link" to="/dashboard">Dashboard</Link>
                    </li>
                </ul>
                <div className="d-lg-flex align-items-center">
                    <div className="d-none d-lg-block">
                        <div className={toggleDropdown ? "dropdown show" : "dropdown"}>
                            <a href="#toggle" onClick={(e) => handleToggleDropdown(e)} role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded={toggleDropdown}>
                                <img alt="user_avatar" src={props.profile.avatar_url !== "" ? props.profile.avatar_url : props.default_avatar} className="avatar" />
                            </a>
                            <div className={toggleDropdown ? "dropdown-menu dropdown-menu-right show" : "dropdown-menu dropdown-menu-right"}>
                                <a href="#account" onClick={(e) => handleNavigate(e, '/account')} className="dropdown-item">Account Settings</a>
                                <>{props.profile.role === 'admin' &&
                                    <a href="#admin" onClick={e => handleNavigate(e, '/admin')} className="dropdown-item">Admin Settings</a>
                                }</>
                                <a href="#logout" onClick={(e) => Handlelogout(e)} className="dropdown-item">Log Out</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
export default Navbar;