import "./Privacy.css";
import React from "react";
import { useNavigate, Link } from "react-router-dom";

function Terms() {
    const navigate = useNavigate();
    return(
<div className="container">
  <br />
  <div align="left">
    <span className="tosHeader">Terms of Service for customer.shawgorealestate.com</span>
  </div>
  <br />
  <span className="tosTitle">Introduction</span>
  <br /><br /> Welcome to customer.shawgorealestate.com. This website is owned and operated by Shawgo Real Estate LLC. By visiting our website and
  accessing the information, resources, services, products, and tools we provide, you understand and agree to accept and
  adhere to the following terms and conditions as stated in this policy (hereafter referred to as 'User Agreement'), along
  with the terms and conditions as stated in our Privacy Policy (please refer to the Privacy Policy section below for more
  information).
  <br /><br /> This agreement is in effect as of October 4, 2021.
  <br /><br /> We reserve the right to change this User Agreement from time to time without notice. You acknowledge and agree
  that it is your responsibility to review this User Agreement periodically to familiarize yourself with any modifications.
  Your continued use of this site after such modifications will constitute acknowledgment and agreement of the modified terms
  and conditions.
  <br /><br /><span className="tosTitle">Responsible Use and Conduct</span>
  <br /><br /> By visiting our website and accessing the information, resources, services, products, and tools we provide for
  you, either directly or indirectly (hereafter referred to as 'Resources'), you agree to use these Resources only for the
  purposes intended as permitted by (a) the terms of this User Agreement, and (b) applicable laws, regulations and generally
  accepted online practices or guidelines.
  <br /><br /> Wherein, you understand that:
  <br /><br /> a. In order to access our Resources, you may be required to provide certain information about yourself (such as
  identification, contact details, etc.) as part of the registration process, or as part of your ability to use the Resources.
  You agree that any information you provide will always be accurate, correct, and up to date.
  <br /><br /> b. You are responsible for maintaining the confidentiality of any login information associated with any account
  you use to access our Resources. Accordingly, you are responsible for all activities that occur under your account/s.
  <br /><br /> c. Accessing (or attempting to access) any of our Resources by any means other than through the means we provide,
  is strictly prohibited. You specifically agree not to access (or attempt to access) any of our Resources through any automated,
  unethical or unconventional means.
  <br /><br /> d. Engaging in any activity that disrupts or interferes with our Resources, including the servers and/or networks
  to which our Resources are located or connected, is strictly prohibited.
  <br /><br /> e. Attempting to copy, duplicate, reproduce, sell, trade, or resell our Resources is strictly prohibited.
  <br /><br /> f. You are solely responsible any consequences, losses, or damages that we may directly or indirectly incur or
  suffer due to any unauthorized activities conducted by you, as explained above, and may incur criminal or civil liability.
  <br /><br /> g. We may provide various open communication tools on our website, such as blog comments, blog posts, public chat,
  forums, message boards, newsgroups, product ratings and reviews, various social media services, etc. You understand that
  generally we do not pre-screen or monitor the content posted by users of these various communication tools, which means
  that if you choose to use these tools to submit any type of content to our website, then it is your personal responsibility
  to use these tools in a responsible and ethical manner. By posting information or otherwise using any open communication
  tools as mentioned, you agree that you will not upload, post, share, or otherwise distribute any content that:
  <br /><br /> i. Is illegal, threatening, defamatory, abusive, harassing, degrading, intimidating, fraudulent, deceptive, invasive,
  racist, or contains any type of suggestive, inappropriate, or explicit language;<br /> ii. Infringes on any trademark, patent,
  trade secret, copyright, or other proprietary right of any party;<br /> Iii. Contains any type of unauthorized or unsolicited
  advertising;<br /> Iiii. Impersonates any person or entity, including any Shawgo Real Estate LLC employees or representatives.<br />
  <br /><br /> We have the right at our sole discretion to remove any content that, we feel in our judgment does not comply with
  this User Agreement, along with any content that we feel is otherwise offensive, harmful, objectionable, inaccurate, or
  violates any 3rd party copyrights or trademarks. We are not responsible for any delay or failure in removing such content.
  If you post content that we choose to remove, you hereby consent to such removal, and consent to waive any claim against
  us.
  <br /><br /> h. We do not assume any liability for any content posted by you or any other 3rd party users of our website. However,
  any content posted by you using any open communication tools on our website, provided that it doesn't violate or infringe
  on any 3rd party copyrights or trademarks, becomes the property of Shawgo Real Estate LLC, and as such, gives us a perpetual,
  irrevocable, worldwide, royalty-free, exclusive license to reproduce, modify, adapt, translate, publish, publicly display
  and/or distribute as we see fit. This only refers and applies to content posted via open communication tools as described,
  and does not refer to information that is provided as part of the registration process, necessary in order to use our Resources.
  All information provided as part of our registration process is covered by our <Link to="/privacy" onClick={(event) => { navigate.replace("/privacy"); return event.preventDefault; }}> privacy policy </Link>.
  <br /><br /> i. You agree to indemnify and hold harmless Shawgo Real Estate LLC and its parent company and affiliates, and their
  directors, officers, managers, employees, donors, agents, and licensors, from and against all losses, expenses, damages
  and costs, including reasonable attorneys' fees, resulting from any violation of this User Agreement or the failure to
  fulfill any obligations relating to your account incurred by you or any other person using your account. We reserve the
  right to take over the exclusive defense of any claim for which we are entitled to indemnification under this User Agreement.
  In such event, you shall provide us with such cooperation as is reasonably requested by us.
  <br /><br /><span className="tosTitle">Privacy</span>
  <br /><br /> Your privacy is very important to us, which is why we've created a separate Privacy Policy in order to explain
  in detail how we collect, manage, process, secure, and store your private information. Our privacy policy is included under
  the scope of this User Agreement. To read our privacy policy in its entirety, <Link to="/privacy" onClick={(event) => { navigate.replace("/privacy"); return event.preventDefault; }}>click here </Link>.
  <br /><br /><span className="tosTitle">Limitation of Warranties</span>
  <br /><br /> By using our website, you understand and agree that all Resources we provide are "as is" and "as available". This
  means that we do not represent or warrant to you that:<br /> i) the use of our Resources will meet your needs or requirements.<br />  ii) the use of our Resources will be uninterrupted, timely, secure or free from errors.<br /> iii) the information obtained
  by using our Resources will be accurate or reliable, and<br /> iv) any defects in the operation or functionality of any Resources
  we provide will be repaired or corrected.<br />
  <br /><br /> Furthermore, you understand and agree that:
  <br /><br /> v) any content downloaded or otherwise obtained through the use of our Resources is done at your own discretion
  and risk, and that you are solely responsible for any damage to your computer or other devices for any loss of data that
  may result from the download of such content.<br /> vi) no information or advice, whether expressed, implied, oral or written,
  obtained by you from Shawgo Real Estate LLC or through any Resources we provide shall create any warranty, guarantee, or conditions
  of any kind, except for those expressly outlined in this User Agreement.<br />
  <br /><br /><span className="tosTitle">Limitation of Liability</span>
  <br /><br /> In conjunction with the Limitation of Warranties as explained above, you expressly understand and agree that any
  claim against us shall be limited to the amount you paid, if any, for use of products and/or services. Shawgo Real Estate LLC
  will not be liable for any direct, indirect, incidental, consequential or exemplary loss or damages which may be incurred
  by you as a result of using our Resources, or as a result of any changes, data loss or corruption, cancellation, loss of
  access, or downtime to the full extent that applicable limitation of liability laws apply.
  <br /><br /><span className="tosTitle">Copyrights/Trademarks</span>
  <br /><br /> All content and materials available on customer.shawgorealestate.com, including but not limited to text, graphics, website
  name, code, images and logos are the intellectual property of Shawgo Real Estate LLC, and are protected by applicable copyright
  and trademark law. Any inappropriate use, including but not limited to the reproduction, distribution, display or transmission
  of any content on this site is strictly prohibited, unless specifically authorized by Shawgo Real Estate LLC.
  <br /><br /><span className="tosTitle">Termination of Use</span>
  <br /><br /> You agree that we may, at our sole discretion, suspend or terminate your access to all or part of our website
  and Resources with or without notice and for any reason, including, without limitation, breach of this User Agreement.
  Any suspected illegal, fraudulent or abusive activity may be grounds for terminating your relationship and may be referred
  to appropriate law enforcement authorities. Upon suspension or termination, your right to use the Resources we provide
  will immediately cease, and we reserve the right to remove or delete any information that you may have on file with us,
  including any account or login information.
  <br /><br /><span className="tosTitle">Governing Law</span>
  <br /><br /> This website is controlled by Shawgo Real Estate LLC. It can be accessed by most countries around the world. By accessing
  our website, you agree that the statutes and laws of our state, without regard to the conflict of laws and the United Nations
  Convention on the International Sales of Goods, will apply to all matters relating to the use of this website and the purchase
  of any products or services through this site.
  <br /><br /> Furthermore, any action to enforce this User Agreement shall be brought in the federal or state courts You hereby
  agree to personal jurisdiction by such courts, and waive any jurisdictional, venue, or inconvenient forum objections to
  such courts.
  <br /><br /><span className="tosTitle">Guarantee</span>
  <br /><br /> UNLESS OTHERWISE EXPRESSED, Shawgo Real Estate LLC EXPRESSLY DISCLAIMS ALL WARRANTIES AND CONDITIONS OF ANY KIND, WHETHER
  EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO THE IMPLIED WARRANTIES AND CONDITIONS OF MERCHANTABILITY, FITNESS FOR
  A PARTICULAR PURPOSE AND NON-INFRINGEMENT.
  <br /><br />
  <p><span className="tosTitle">Contact Information</span> <br /><br /> If you have any questions or comments
    about these our Terms of Service as outlined above, you can contact us at:<br /><br />dana@shawgorealestate.com<br /><br
    /> <br /> <br /><br /></p>
</div>
    );
}
export default Terms;
