import { React, useState, useEffect } from 'react';
import Avatars from './Avatars';
import { getSetRoleClaim, getSetUserDisabled, getUsers, createNewUser } from './firebase';
import { Modal, Button } from "react-bootstrap";

function Admin() {
    const [userFilterText, setUserFilterText] = useState("");
    const [filtered_users, setFilteredUsers] = useState([]);
    const [users, setUsers] = useState([]);
    const [toggleAddUser, setToggleAddUser] = useState(false);
    const [first_name, setFirstName] = useState("");
  const [last_name, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [title, setTitle] = useState("");

    useEffect(() => {
        getUsers().then(r => {
            setUsers(r);
            setFilteredUsers(r);
        });
    }, []);

    useEffect(() => {
        filterUsers(userFilterText);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [users])

    const handleUserFilterTextChange = (e) => {
        setUserFilterText(e.target.value);
        filterUsers(e.target.value);
    }

    const filterUsers = (text) => {
        let filter_users = users;
        if (text === 0)
            setFilteredUsers(users);
        else
            setFilteredUsers(filter_users.filter(user => user.first_name.toLowerCase().indexOf(text.toLowerCase()) !== -1 || user.last_name.toLowerCase().indexOf(text.toLowerCase()) !== -1));
    }

    const handleToggleNewUser = (e) => {
        e.preventDefault();
        setToggleAddUser(true);
    }

    const handleAddUser = async (e) => {
        e.preventDefault();
        await createNewUser(email, first_name, last_name, title, phone, email);
            getUsers().then(r => {
                //console.log('2')
                setUsers(r);
                setToggleAddUser(false);
                setFirstName("");
                setLastName("");
                setTitle("");
                setEmail("");
                setPhone("");
            });
    }

    const handleAddUserClose = (e) => {
        e.preventDefault();
        setToggleAddUser(false);

    }

    const handleUserDisabled = async (e, id) => {
        e.preventDefault();

        await getSetUserDisabled(id, "disabled");
        getUsers().then(r => {
            setUsers(r);
        });
    }

    const handleUserEnable = async (e, id) => {
        e.preventDefault();

        await getSetUserDisabled(id, "enabled");
        getUsers().then(r => {
            setUsers(r);
        });
    }

    const handleRoleChange = async (e, id) => {
        const value = e.target.value;
        await getSetRoleClaim(id, value);
        getUsers().then(r => {
            setUsers(r);
        });
    }

    const handleUserInput = (e) => {
        const name = e.target.name;
        const value = e.target.value;
    
        if (name === "profile-first-name") {
          setFirstName(value);
        }
        if (name === "profile-last-name") {
          setLastName(value);
        }
        if (name === "profile-email") {
          setEmail(value);
        }
        if (name === "profile-phone") {
          setPhone(value);
        }
    
        if (name === "profile-title") {
          setTitle(value);
        }
      }

    return (
        <div className="main-container">
            <div className="container">
                <div className="row justify-content-center mt-5">
                    <div className="col-lg-11 col-xl-10">
                        <div className="tab-content">
                            <div className="row content-list-head">
                                <div className="col-auto">
                                    <h3>Manage Users</h3>
                                    <button onClick={e => handleToggleNewUser(e)} className="btn btn-round" data-toggle="modal" data-target="#user-add-modal">
                                        <i className="material-icons">add</i>
                                    </button>
                                </div>
                                <form className="col-md-auto">
                                    <div className="input-group input-group-round">
                                        <div className="input-group-prepend">
                                            <span className="input-group-text">
                                                <i className="material-icons">filter_list</i>
                                            </span>
                                        </div>
                                        <input type="search" className="form-control filter-list-input" value={userFilterText} onChange={e => handleUserFilterTextChange(e)} placeholder="Filter Users" aria-label="Filter teams" />
                                    </div>
                                </form>
                            </div>
                            <div className="tab-pane fade show active" id="users" role="tabpanel" data-filter-list="content-list-body">
                                {filtered_users.map((user) => (
                                    <div className="card">
                                        <div className="card-body">
                                            <div className="row align-items-center">
                                                <div className="col-auto">
                                                    <Avatars users={[user.uid]} master_members_list={users} />
                                                </div>
                                                <div className="col d-flex align-items-center">
                                                    <span>{user.first_name + ' ' + user.last_name + ' (' + user.email + ')'}</span>
                                                </div>
                                                <div className="col d-flex align-items-center">
                                                    <span>Role:</span>
                                                    <select value={user.role} onChange={e => handleRoleChange(e, user.uid)} name="project-workflow-template" className="form-select col" aria-label="Project Workflow Template">
                                                        <option defaultValue="">Select a role</option>
                                                        <option value="admin">Administrator</option>
                                                        <option value="client">Client</option>
                                                        <option value="vendor">Vendor</option>
                                                        <option value="shawgo">Team Shawgo</option>
                                                    </select>
                                                </div>
                                                {!user.disabled &&
                                                    <div className="col-auto">
                                                        <button onClick={e => handleUserDisabled(e, user.uid)} className="btn btn-sm btn-danger">
                                                            Disable Account
                                                        </button>
                                                    </div>
                                                }
                                                {user.disabled &&
                                                    <div className="col-auto">
                                                        <button onClick={e => handleUserEnable(e, user.uid)} className="btn btn-sm btn-primary">
                                                            Enable Account
                                                        </button>
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                ))
                                }
                            </div>
                        </div>
                    </div>
                </div>
                <Modal show={toggleAddUser} animation={false}>
                    <Modal.Header>
                        <Modal.Title>Create New User</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                    <form>
                        <div className="form-group row align-items-center">
                          <label className="col-3">First Name</label>
                          <div className="col">
                            <input type="text" placeholder="First name" value={first_name} name="profile-first-name" className="form-control" required="" onChange={e => handleUserInput(e)} />
                          </div>
                        </div>
                        <div className="form-group row align-items-center">
                          <label className="col-3">Last Name</label>
                          <div className="col">
                            <input type="text" placeholder="First name" value={last_name} name="profile-last-name" className="form-control" required="" onChange={e => handleUserInput(e)} />
                          </div>
                        </div>
                        <div className="form-group row align-items-center">
                          <label className="col-3">Job Title</label>
                          <div className="col">
                            <input type="text" placeholder="Job Title" value={title} name="profile-title" className="form-control" onChange={e => handleUserInput(e)} />
                          </div>
                        </div>
                        <div className="form-group row align-items-center">
                          <label className="col-3">Email</label>
                          <div className="col">
                            <input type="email" placeholder="Enter email address" value={email} name="profile-email" className="form-control" onChange={e => handleUserInput(e)} />
                          </div>
                        </div>
                        <div className="form-group row align-items-center">
                          <label className="col-3">Cell Phone</label>
                          <div className="col">
                            <input type="tel" placeholder="Enter cell phone number" value={phone} name="profile-phone" className="form-control" onChange={e => handleUserInput(e)} />
                          </div>
                        </div>
                      </form>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={e => handleAddUserClose(e)}>
                            Close
                        </Button>
                        <Button variant="primary" onClick={e => handleAddUser(e)}>
                            Create User
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div>
        </div>

    )
}

export default Admin;