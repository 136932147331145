import { React, useEffect, useState } from 'react';
import { Modal, Button } from "react-bootstrap";
import Flatpickr from "react-flatpickr";
import './NewProject.css';
import { getAgents, getCustomers, addNewProject, getTeams, CurrentUser } from './firebase';
import { FormErrors } from './FormErrors';
import Avatars from "./Avatars";
import { v4 } from 'uuid';

function NewProject(props) {
    const errors = { name: '', descr: '', customer: '', status: '', agent: '', template: '', close_dt: '', teams: '' };
    const [teams, setTeams] = useState([]);
    const [new_project_teams, setNewProjectTeams] = useState([]);
    const [show, setShow] = useState(false);
    const [close_dt, SetCloseDt] = useState(new Date());
    const [name, setName] = useState("");
    const [descr, setDescr] = useState("");
    const [customer, setCustomer] = useState("");
    const [customers, setCustomers] = useState([]);
    const [status] = useState("Not Launched");
    const [leadSource, setLeadSource] = useState("");
    const [agent, setAgent] = useState("");
    const [seller_agent, setSellerAgent] = useState("");
    const [agents, setAgents] = useState([]);
    const [workflow_template, SetWorkflowTemplate] = useState("");
    const [current_view, setCurrentView] = useState("details");
    const [add_project_form_errors, SetAddProjectFormErrors] = useState(errors);
    const current_user = CurrentUser();

    useEffect(() => {

        //get list of customers from db...
        if (show) {
            getCustomers().then((r) => {
                setCustomers(r);
            });

            //get list of users flagged as agents from the db...
            getAgents().then((r) => {
                setAgents(r);
            });

            getTeams().then((r) => {
                setTeams(r);
            })
        }
        setShow(props.show);
    }, [props.show, show]);

    const handleFormInputChange = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        //console.log(name);
        //console.log(value);

        if (name === "project-name") {
            setName(value);
        }

        if (name === "project-description") {
            setDescr(value);
        }

        if (name === "project-customer") {
            setCustomer(value);
        }

        if (name === "project-buyer-agent") {
            setAgent(value);
        }

        if (name === "project-seller-agent") {
            setSellerAgent(value);
        }

        if (name === "project-workflow-template") {
            SetWorkflowTemplate(value);
        }

        if (name === "project-lead-source") {
            setLeadSource(value);
        }

        if (new_project_teams.length === 0) {

        }


    }

    const handleCurrentViewChange = (e, view) => {
        e.preventDefault();
        setCurrentView(view);
    }

    const teamisOnProject = (id) => {
        
        let index = new_project_teams.findIndex(element => element === id);
        if (index === -1)
            return false;
        else
            return true;
    }

    const resetForm = () => {
        setName("");
        setDescr("");
        setCustomer("");
        setAgent("");
        SetWorkflowTemplate("");
        setNewProjectTeams([]);

    }

    const handleClose = (e) => {
        e.preventDefault();
        SetAddProjectFormErrors(errors);
        resetForm();
        setCurrentView('details');
        setShow(false);
        props.onClose();

    }

    const handleAddProject = (e) => {
        e.preventDefault();
        const form_valid = validateForm();
        //console.log(form_valid);
        if (form_valid) {
            //add project to db...
            let new_project = {
                id: v4(),
                name: name,
                descr: descr,
                customer_id: customer,
                agent_id: agent,
                seller_agent_id: seller_agent,
                lead_source: leadSource,
                status: status,
                close_dt: close_dt,
                workflow_template_id: workflow_template,
                files:[],
                activity:[],
                teams: new_project_teams,
                create_dt: new Date(),
                created_by: current_user.uid
            }

           addNewProject(new_project).then(() => {
               console.log('new project was added.... yes.....');
               SetAddProjectFormErrors(errors);
               resetForm();
               setCurrentView('details');
               setShow(false);
               props.onClose();
           });
        }


    }

    const handleTeamChanged = (e) => {

        let temp_project_teams = [...new_project_teams];

        //check if already a member of team or not. 
        let index = temp_project_teams.findIndex(element => element === e.target.id);


        if (index === -1) {
            temp_project_teams.push(e.target.id);

            setNewProjectTeams(temp_project_teams);
            return true;
        }
        else {
            temp_project_teams.splice(index, 1);
            setNewProjectTeams(temp_project_teams);
            return false;
        }

    }

    const validateForm = () => {
        let form_valid = true;
        let _errors = errors;

        if (name.length === 0) {
            _errors.name = 'Project Name is a required field.';
            form_valid = false;
        }

        if (descr.length === 0) {
            _errors.descr = 'Project Description is a required field.';
            form_valid = false;
        }

        if ( customer === "" ) {
            _errors.customer = 'Project Customer is a required field.';
            form_valid = false;
        }

        if ( agent === "" ) {
            _errors.agent = 'Project Agent is a required field.';
            form_valid = false;
        }

        if ( workflow_template === "" ) {
            _errors.template = 'Project Workflow Template is a required field.';
            form_valid = false;
        }

        if ( new_project_teams.length === 0 ) {
            _errors.teams = 'Project must have atleast one team assigned.';
            form_valid = false;
        }

        SetAddProjectFormErrors(_errors);

        return form_valid;

    }

    return (
        <div>
            {show &&
                <Modal show={props.show} animation={false}>
                    <Modal.Header>
                        <Modal.Title>New Project</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <ul className="nav nav-tabs nav-fill" role="tablist">
                            <li className="nav-item" key="project-add-details">
                                <a onClick={e => handleCurrentViewChange(e, 'details')} className={current_view === 'details' ? "nav-link active" : "nav-link"} id="project-add-details-tab" data-toggle="tab" href="#project-add-details" role="tab" aria-controls="project-add-details" aria-selected="true">Details</a>
                            </li>
                            <li className="nav-item" key="team-add-">
                                <a onClick={e => handleCurrentViewChange(e, 'teams')} className={current_view === 'teams' ? "nav-link active" : "nav-link"} id="team-add-tab" data-toggle="tab" href="#team-add" role="tab" aria-controls="team-add" aria-selected="true">Teams</a>
                            </li>
                        </ul>
                        <div className="modal-body">
                            <FormErrors formErrors={add_project_form_errors} />
                            {current_view === 'details' &&
                                <div className="tab-content">
                                    <div className="tab-pane fade active show" id="project-add-details" role="tabpanel">
                                        <h6>General Details</h6>
                                        <div className="form-group row align-items-center">
                                            <label className="col-3">Name</label>
                                            <input className="form-control col" type="text" value={name} onChange={e => handleFormInputChange(e)} placeholder="Project name" name="project-name" />
                                        </div>
                                        <div className="form-group row">
                                            <label className="col-3">Description</label>
                                            <textarea className="form-control col" rows="3" value={descr} onChange={e => handleFormInputChange(e)} placeholder="Project description" name="project-description"></textarea>
                                        </div>
                                        <div className="form-group row">
                                            <label className="col-3" htmlFor="project-lead-source">Lead Source</label>

                                            <select value={leadSource} onChange={e => handleFormInputChange(e)} name="project-lead-source" className="form-select col" aria-label="Project Lead Source">
                                                <option defaultValue="">Select a lead source</option>
                                                <option value="website">Website</option>
                                                <option value="zillow">Zillow</option>
                                                <option value="realtor">Realtor.com</option>
                                                <option value="word-of-mouth">Word-of-mouth</option>
                                                <option value="office">Office</option>
                                                <option value="past client">Past Client</option>
                                            </select>
                                        </div>
                                        <div className="form-group row">
                                            <label className="col-3" htmlFor="project-customer">Customer</label>

                                            <select value={customer} onChange={e => handleFormInputChange(e)} name="project-customer" className="form-select col" aria-label="Project Customer">
                                                <option defaultValue="">Select a customer</option>
                                                {customers.map((customer) => (
                                                    <option key={customer.uid} value={customer.uid}> {customer.first_name + ' ' + customer.last_name}</option>
                                                ))
                                                }
                                            </select>
                                        </div>
                                        <div className="form-group row">
                                            <label className="col-3" htmlFor="project-buyer-agent">Buyer Agent</label>
                                            <select value={agent} onChange={e => handleFormInputChange(e)} name="project-buyer-agent" className="form-select col" aria-label="Project Buyer Agent">
                                                <option defaultValue="">Select a buyer agent</option>
                                                {agents.map((agent) => (
                                                    <option key={agent.uid} value={agent.uid}> {agent.first_name + ' ' + agent.last_name}</option>
                                                ))
                                                }
                                            </select>
                                        </div>
                                        <div className="form-group row">
                                            <label className="col-3" htmlFor="project-seller-agent">Seller Agent</label>
                                            <select value={seller_agent} onChange={e => handleFormInputChange(e)} name="project-seller-agent" className="form-select col" aria-label="Project Seller Agent">
                                                <option defaultValue="">Select a seller agent</option>
                                                {agents.map((agent) => (
                                                    <option key={agent.uid} value={agent.uid}> {agent.first_name + ' ' + agent.last_name}</option>
                                                ))
                                                }
                                            </select>
                                        </div>
                                        <div className="form-group row">
                                            <label className="col-3" htmlFor="project-status">Status</label>
                                            <input className="form-control col" type="text" value={status} placeholder="Project name" name="project-status" readOnly={true} />
                                        </div>
                                        <hr />
                                        <h6>Timeline</h6>
                                        <div className="form-group row align-items-center">
                                            <label className="col-3">Close Date</label>
                                            <Flatpickr
                                                options={{
                                                    minDate: "2021-01-01",
                                                    altInput: true,
                                                    altFormat: "F j, Y",
                                                    dateFormat: "Y-m-d"
                                                }}
                                                value={close_dt}
                                                onChange={date => {
                                                    SetCloseDt(date[0]);
                                                }}
                                            />
                                        </div>
                                        <hr />
                                        <h6>WorkFlow</h6>
                                        <div className="form-group row">
                                            <label className="col-3" htmlFor="project-workflow-template">Template</label>

                                            <select value={workflow_template} onChange={e => handleFormInputChange(e)} name="project-workflow-template" className="form-select col" aria-label="Project Workflow Template">
                                                <option defaultValue="">Select a workflow template</option>
                                                <option value="Home - Lender">Home - Lender</option>
                                                <option value="Home - Cash">Home - Cash</option>
                                                <option value="Land - Lender">Land - Lender</option>
                                                <option value="Land - Cash">Land - Cash</option>
                                            </select>
                                        </div>
                                        <div className="alert alert-warning text-small" role="alert">
                                            <span>Workflow Templates build the initial task and task team assignments for this project. You can customize before launching this project.</span>
                                        </div>
                                    </div>
                                </div>
                            }
                            {current_view === 'teams' &&
                                <div className="tab-content">
                                    <div className="teams-manage" data-filter-list="form-group-teams">
                                        <div className="form-group-teams filter-list-1633658121439">
                                            {teams &&
                                                teams.map((team) => (
                                                    <div className="custom-control custom-checkbox" name={team.id} key={team.id}>
                                                        <input onChange={e => handleTeamChanged(e)} type="checkbox" className="custom-control-input" name={team.id} id={team.id} checked={teamisOnProject(team.id)} />
                                                        <label className="custom-control-label" htmlFor={team.id}>
                                                            <span className="d-flex align-items-center">
                                                                <span className="h6 mb-0 SPAN-filter-by-text" data-filter-by="text">{team.name}</span>
                                                                <Avatars users={team.members} master_members_list={props.master_members_list} align="center" />
                                                            </span>
                                                        </label>
                                                    </div>
                                                ))
                                            }
                                        </div>
                                    </div>
                                </div>
                            }
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={e => handleClose(e)}>
                            Close
                        </Button>
                        <Button variant="primary" onClick={e => handleAddProject(e)}>
                            Create Project
                        </Button>
                    </Modal.Footer>
                </Modal>
            }
        </div>
    )

}

export default NewProject;